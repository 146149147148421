import { BarGraph } from "features/impacts";
import { useLocation } from "react-router-dom";
import { useGetInitiativeImpactsQuery } from "features/impacts/impactsSlice";
import { skipToken } from "@reduxjs/toolkit/query";

function InitiativeBarGraph() {
  const location = useLocation();
  let impactsData = [];
  const { initiativeId } = (location && location.state) || {};
  const { data, isSuccess } = useGetInitiativeImpactsQuery(
    initiativeId ? initiativeId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  if (isSuccess) {
    impactsData = data.data;
  }
  let allimpactIds = [];
  let j = 0;
  for (let i = 0; i < impactsData.length; i++) {
    allimpactIds[j] = impactsData[i].id;
    j++;
  }

  return (
    <div className='col'>
      <div className='card'>
        <div className='card-header'>
          <h1 className='title-init-bar'>Impacts</h1>
        </div>
        <div className='card-body'>
          {allimpactIds.length > 0 ? (
            allimpactIds.map((impactId) => (
              <div key={impactId}>
                <BarGraph impactId={impactId} />
              </div>
            ))
          ) : (
            <p>No impacts found</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InitiativeBarGraph;

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useState, useEffect } from "react";
import { useGetVendorGoalsQuery } from "features/goals/goalsSlice";
import { useAddClientFeedbackMutation } from "./clientSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";

function FeedbackForm({ clientId, feedbackShow, onClose }) {
  const [formState, setFormState] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const hasErrors = formState.some((goal) => goal.error);
  const navigate = useNavigate();

  const [addClientFeedback] = useAddClientFeedbackMutation({
    refetchOnMountOrArgChange: true,
  });

  const { data: goalsData, isSuccess } = useGetVendorGoalsQuery(
    clientId ? clientId : skipToken,
    {
      refetchOnMountOrArgChange: 1,
    },
  );

  useEffect(() => {
    if (isSuccess && goalsData?.data) {
      const fetchedGoals = goalsData.data.map((goal) => ({
        id: goal.id,
        goal: goal.title,
        healthCheck: "",
        description: "",
      }));
      setFormState(fetchedGoals);
    }
  }, [isSuccess, goalsData]);

  const getPlaceholder = (healthCheck) => {
    switch (healthCheck) {
      case "Doing Great":
        return "What key milestones or results have you achieved so far? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that have advanced this goal. List any measurable results.";
      case "On Target":
        return "What progress have you made so far toward achieving your goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that are meeting expectations. List any measurable results.";
      case "Needs Improvement":
        return "What specific challenges have been holding you back from achieving this goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Are there any areas of improvement? Which impacts/initiatives/projects are not meeting expectations? List any measurable results.";
      default:
        return "";
    }
  };

  const handleInputChange = (index, field, value) => {
    setFormState((prevState) => {
      const updatedState = [...prevState];

      if (field === "description") {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = value;
        const textContent = tempDiv.textContent || tempDiv.innerText || "";

        updatedState[index].error =
          textContent.length > 500
            ? "Description cannot exceed 500 characters."
            : "";

        if (textContent.length === 1) {
          value = `<ul><li>${textContent}</li></ul>`;
        }

        updatedState[index].description = value;
        return updatedState;
      }

      updatedState[index][field] = value;
      return updatedState;
    });
  };

  const handleCancelClick = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmCancel = () => {
    setFormState([]);
    setShowConfirmationModal(false);
    onClose();
  };

  const handleDismissConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const extractPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const listItems = tempDiv.querySelectorAll("li");
    if (listItems.length > 0) {
      return Array.from(listItems)
        .map((li) => `• ${li.innerText.trim()}`)
        .join("\n");
    }

    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleSave = async () => {
    try {
      const feedbackData = formState.map((goal) => ({
        description: extractPlainText(goal.description),
        goal: goal.id.toString(),
        health_check: goal.healthCheck,
        account: clientId.toString(),
      }));
      const result = await addClientFeedback({ body: feedbackData });
      if (!result.error) {
        onClose();
        navigate(`/client-brief/${clientId}`, {
          state: {
            clientId: clientId,
          },
        });
      }
    } catch (error) {
      onClose();
      console.error("Failed to submit feedback:", error);
      alert("Failed to submit feedback. Please try again.");
    }
  };

  if (!feedbackShow) return null;

  return (
    <>
      <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <span className='modal-title'>Feedback Form</span>
              <button
                type='button'
                className='btn-close'
                onClick={onClose}
              ></button>
            </div>
            <div className='modal-body'>
              {!goalsData || !goalsData.data || goalsData.data.length === 0 ? (
                <p className='text-center text-muted'>
                  Please add goals to view the feedback form and client brief.
                </p>
              ) : (
                formState.map((goal, index) => (
                  <div key={goal.id} className='mb-4'>
                    <span>
                      Goal {index + 1}: {goal.goal}
                    </span>
                    <div className='mb-3 d-flex align-items-center'>
                      <p className='mb-0 me-3'>Health Check:</p>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          name={`healthCheck${goal.id}`}
                          id={`doingGreat${goal.id}`}
                          className='form-check-input'
                          value='Doing Great'
                          checked={goal.healthCheck === "Doing Great"}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "healthCheck",
                              e.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`doingGreat${goal.id}`}
                          className='form-check-label'
                        >
                          Doing Great
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          name={`healthCheck${goal.id}`}
                          id={`onTarget${goal.id}`}
                          className='form-check-input'
                          value='On Target'
                          checked={goal.healthCheck === "On Target"}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "healthCheck",
                              e.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`onTarget${goal.id}`}
                          className='form-check-label'
                        >
                          On Target
                        </label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input
                          type='radio'
                          name={`healthCheck${goal.id}`}
                          id={`needsImprovement${goal.id}`}
                          className='form-check-input'
                          value='Needs Improvement'
                          checked={goal.healthCheck === "Needs Improvement"}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "healthCheck",
                              e.target.value,
                            )
                          }
                        />
                        <label
                          htmlFor={`needsImprovement${goal.id}`}
                          className='form-check-label'
                        >
                          Needs Improvement
                        </label>
                      </div>
                    </div>
                    {goal.healthCheck && (
                      <div className='accordion'>
                        <div className='accordion-item'>
                          <span className='accordion-header'>
                            <button
                              className='accordion-button'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#collapseDescription${goal.id}`}
                              aria-expanded='true'
                              aria-controls={`collapseDescription${goal.id}`}
                            >
                              Provide Description
                            </button>
                          </span>
                          <div
                            id={`collapseDescription${goal.id}`}
                            className={`accordion-collapse collapse ${index===0 ? 'show' : ""}`}
                          >
                            <div className='accordion-body'>
                              <ReactQuill
                                id={`description${goal.id}`}
                                key={`${goal.id}-${goal.healthCheck}`}
                                className='react-quill'
                                value={goal.description}
                                modules={{
                                  toolbar: [
                                    [{ list: "bullet" }],
                                    ["bold", "italic", "underline"],
                                    [{ align: [] }],
                                    ["clean"],
                                  ],
                                }}
                                formats={[
                                  "list",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "align",
                                ]}
                                onChange={(value) =>
                                  handleInputChange(index, "description", value)
                                }
                                placeholder={getPlaceholder(goal.healthCheck)}
                                style={{ minHeight: "200px" }}
                              ></ReactQuill>
                              {goal.error && (
                                <div className='text-danger mt-1'>
                                  {goal.error}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
            <div className='modal-footer d-flex justify-content-between align-items-center w-100'>
              <div
                className={`text-danger text-start small mb-0 ${formState.some((goal) => !goal.healthCheck) ? "" : "invisible"}`}
              >
                Please select a Health Check option for all goals before saving.
              </div>

              <div>
                <button
                  type='button'
                  className='btn btn-secondary me-2'
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={handleSave}
                  disabled={
                    hasErrors || formState.some((goal) => !goal.healthCheck)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <span className='modal-title'>Confirm Cancel</span>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleDismissConfirmation}
                ></button>
              </div>
              <div className='modal-body'>
                <p>
                  Are you sure you want to cancel? Any unsaved changes will be
                  lost.
                </p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={handleDismissConfirmation}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={handleConfirmCancel}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackForm;

import { Link } from "react-router-dom";

function Widget({ name, dropDownItems }) {
  return (
    <>
      <div className='card-header d-flex justify-content-between'>
        <div>
          <i
            className='bi bi-grip-vertical'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title=''
            data-bs-original-title='Click &amp; Drag'
            aria-label='Click &amp; Drag'
          ></i>
          <span>{name}</span>
        </div>
        {name !== "Tasks" && name !== "News Feed" && (
          <div className='btn-group' role='group'>
            <div className='dropdown'>
              <button
                className='option-btn'
                data-bs-toggle='dropdown'
                type='button'
              >
                <i
                  className='bi bi-three-dots ms-2'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title=''
                  data-bs-original-title='Options'
                  aria-label='Options'
                ></i>
              </button>
              <ul className='dropdown-menu small-dropdown py-1'>
                {dropDownItems.map((dropDownItem, i) => {
                  return (
                    <div key={i}>
                      <li>
                        {/*TODO: wire-up these with link component from RR*/}
                        {dropDownItem === "Clientboard" ? (
                          <Link className='dropdown-item' to='/clients'>
                            {dropDownItem}
                          </Link>
                        ) : (
                          <a className='dropdown-item' href='/'>
                            {dropDownItem}
                          </a>
                        )}
                      </li>
                      {i === 1 ? <hr className='dropdown-divider'></hr> : null}
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Widget;

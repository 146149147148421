import { ProjectItem } from "features/projects";
import { useGetVendorProjectsQuery } from "features/projects/projectsSlice";

function ProjectsByClient({ clientId }) {
    const { data, isSuccess } = useGetVendorProjectsQuery(clientId, {
        refetchOnMountOrArgChange: true,
    });

    const projectsLists = isSuccess && data?.data?.length > 0 ? data.data : [];

    return (
        <div className='list-group list-group-flush'>
            {projectsLists.length > 0 ? (
                projectsLists.slice(0, 5).map((projectsList, i) => (
                    <ProjectItem
                        key={i}
                        title={projectsList.title}
                        stage={projectsList.stage}
                        dueDate={projectsList.end_date}
                    />
                ))
            ) : (
                <p className="text-black">No projects have been assigned to this client.</p>
            )}
        </div>
    );
}

export default ProjectsByClient;

import { Task } from "common";
import {
	useGetVendorTasksQuery,
	useUpdateTaskMutation,
} from "features/tasks/tasksSlice";

function TasksByClient({ clientId }) {
	let tasks = [];
	const taskForClient = true;
	const numIncompleteTask = 5;
	const { data, isSuccess } = useGetVendorTasksQuery(clientId, {
		refetchOnMountOrArgChange: true,
	});

	const [updateTask] = useUpdateTaskMutation();

	if (isSuccess) {
		tasks = data.data;
	}

	return (
		<>
			<Task tasks={tasks} completedSection={false} updateTask={updateTask} numIncompleteTask={numIncompleteTask} taskForClient={taskForClient} />
		</>
	);
}

export default TasksByClient;

import RegisterUser  from "features/user/RegisterUser";
function Signup() {
  return (
    <>
      <RegisterUser />
    </>
  );
}

export default Signup;

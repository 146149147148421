import { useGetGoalsQuery } from "features/goals/goalsSlice";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Attachment from "common/attachments/Attachment";
import {
  useGetGoalAttachmentsQuery,
  useAddGoalAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import { store } from "app/store";
import { GoalForm } from "features/goals";
import {
  useDeleteGoalMutation,
  useGetGoalsHierarchyQuery,
} from "features/goals/goalsSlice";
import { DeleteDialog } from "common";
import { Hierarchy, Dates, Budget } from "common";
import { skipToken } from "@reduxjs/toolkit/query";
import { DecisionPoint } from "features/decision-point";

const DELETE_DIALOG_TITLE = "Goals";
const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete this goal?`;

function GoalDetail() {
  let goalInfo = [];
  let attachmentsInfo = [];
  let consultantId;
  let goalHierarchy = [];

  const location = useLocation();
  const navigate = useNavigate();
  const [inventoryId, setInventoryId] = useState(1);
  const [model, setModel] = useState(false);
  const { goalId } = (location && location.state) || {};

  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  const { data, isSuccess } = useGetGoalsQuery(goalId ? goalId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    goalInfo = data.data;
  }
  const [deleteGoal] = useDeleteGoalMutation();

  const goalAttachmentsData = useGetGoalAttachmentsQuery(
    goalId ? goalId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (goalAttachmentsData.isSuccess) {
    attachmentsInfo = goalAttachmentsData.data.attachments;
  }

  const [addGoalAttachemnts] = useAddGoalAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleAddAttachment = async (formData) => {
    formData.append("goal_id", goalId);
    formData.append("user_id", consultantId);
    await addGoalAttachemnts(formData);
  };

  const hideDialog = () => {
    setModel(false);
  };

  const [deleteGoalAttachemnts] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteGoalAttachemnts(attachmentId);
  };

  const deleteDialog = () => {
    //setGoalId(goalId);
    return setModel(true);
  };

  const deleteHandle = async () => {
    if (goalId) {
      await deleteGoal(goalId);
      //setGoalId(null);
    }
    setModel(false);
    navigate("/goals");
  };
  //Goal Hierarchy
  const { data: goalHierarchyData, isSuccess: isGoalHierarchySuccess } =
    useGetGoalsHierarchyQuery(goalId, {
      refetchOnMountOrArgChange: true,
    });

  if (!isGoalHierarchySuccess || !goalHierarchyData.data) {
    return null;
  }

  if (isGoalHierarchySuccess && goalHierarchyData.data) {
    goalHierarchy = goalHierarchyData.data;
  }

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <Hierarchy hierarchy={goalHierarchy} />;
      case 2:
        return (
          <DecisionPoint goalId={goalInfo.id} goalTitle={goalInfo.title} />
        );
      case 3:
        return (
          <Dates
            created={goalInfo.created_on_date}
            started={goalInfo.start_date}
            ended={goalInfo.end_date}
            lastModified={goalInfo.modified_on_date}
          />
        );
      case 4:
        return (
          <Budget
            allocatedBudget={goalInfo.allocated_budget}
            currentSpend={goalInfo.spend_budget}
          />
        );

      case 6:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className='col-4'>
        <div className='card p-4'>
          <div
            className='card-body d-flex flex-column'
            data-testid='test-goal-title'
          >
            <div className='heading-row d-flex'>
              <i className='bi bi-bullseye icon-title me-3'></i>
              <h1 className='goal-title-details'>{goalInfo.title}</h1>
            </div>
            <div className='goal-status'>
              {goalInfo.stage === "Not-Started" ? (
                <span className='badge text-bg-danger small'>
                  {goalInfo.stage}
                </span>
              ) : goalInfo.stage === "In-Progress" ? (
                <span className='badge text-bg-warning small'>
                  {goalInfo.stage}
                </span>
              ) : (
                <span className='badge text-bg-success small'>
                  {goalInfo.stage}
                </span>
              )}
              <div>
                <GoalForm
                  formInputs={{
                    formType: "Goal",
                    formAction: "Edit",
                    itemDetails: {
                      id: goalInfo.id,
                    },
                  }}
                />

                <i
                  className='bi bi-trash-fill'
                  data-bs-placment='center'
                  title='Delete'
                  onClick={() => deleteDialog()}
                ></i>
              </div>
            </div>
            <p className='goal-description'>{goalInfo.description}</p>
            <div className='goal-icon-group'>
              <button
                className='btn-basic'
                onClick={() => handleChange(1)}
                data-toggle='tooltip'
                data-placement='top'
                title='Hierarchy'
              >
                <i
                  className={`bi bi-diagram-3 ${
                    inventoryId === 1 ? "active" : ""
                  }`}
                ></i>
              </button>
              <button
                className='btn-basic'
                onClick={() => handleChange(2)}
                data-toggle='tooltip'
                data-placement='top'
                title='Decision Point'
              >
                <i
                  className={`bi bi-signpost-split-fill ${
                    inventoryId === 2 ? "active" : ""
                  }`}
                ></i>
              </button>
              <button
                className='btn-basic'
                onClick={() => handleChange(3)}
                data-toggle='tooltip'
                data-placement='top'
                title='Calendar'
              >
                <i
                  className={`bi bi-calendar-date ${
                    inventoryId === 3 ? "active" : ""
                  }`}
                ></i>
              </button>
              <button
                className='btn-basic'
                onClick={() => handleChange(4)}
                data-toggle='tooltip'
                data-placement='top'
                title='Budget'
              >
                <i
                  className={`bi bi-coin ${inventoryId === 4 ? "active" : ""}`}
                ></i>
              </button>
              <button
                className='btn-basic'
                onClick={() => handleChange(6)}
                data-toggle='tooltip'
                data-placement='top'
                title='Attachment'
              >
                <i
                  className={`bi bi-paperclip ${
                    inventoryId === 6 ? "active" : ""
                  }`}
                ></i>
              </button>
            </div>
            <div id='inventory-content' className='mt-4'>
              {renderInventoryContent()}
            </div>
          </div>
        </div>
        {model === true ? (
          <DeleteDialog
            dialogTitle={DELETE_DIALOG_TITLE}
            descriptionText={DELETE_DIALOG_MESSAGE}
            hide={() => hideDialog()}
            handleDelete={() => deleteHandle()}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default GoalDetail;

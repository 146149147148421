import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useState } from "react";
import {
  useAddGoalFeedbackMutation,
  useGetGoalsQuery,
} from "features/goals/goalsSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";

function FeedbackForGoal({ clientId, goalId, feedbackGoalShow, onClose }) {
  let goalsData = [];
  const [healthCheck, setHealthCheck] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const MAX_DESCRIPTION_LENGTH = 500;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const { data, isSuccess } = useGetGoalsQuery(goalId ? goalId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    goalsData = data.data;
  }

  const [addGoalFeedback] = useAddGoalFeedbackMutation({
    refetchOnMountOrArgChange: true,
  });

  const getPlaceholder = (healthCheck) => {
    switch (healthCheck) {
      case "Doing Great":
        return "What key milestones or results have you achieved so far? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that have advanced this goal. List any measurable results.";
      case "On Target":
        return "What progress have you made so far toward achieving your goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that are meeting expectations. List any measurable results.";
      case "Needs Improvement":
        return "What specific challenges have been holding you back from achieving this goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Are there any areas of improvement? Which impacts/initiatives/projects are not meeting expectations? List any measurable results.";
      default:
        return "";
    }
  };

  const handleCancelClick = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmCancel = () => {
    setShowConfirmationModal(false);
    onClose();
  };

  const handleDismissConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const extractPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const listItems = tempDiv.querySelectorAll("li");
    if (listItems.length > 0) {
      return Array.from(listItems)
        .map((li) => `• ${li.innerText.trim()}`)
        .join("\n");
    }

    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleSave = async () => {
    try {
      const cleanedDescription = extractPlainText(description);

      const feedbackData = {
        description: cleanedDescription,
        goal: goalId.toString(),
        health_check: healthCheck,
      };

      const result = await addGoalFeedback({ body: feedbackData });

      if (!result.error) {
        onClose();
        navigate(`/client-brief/${clientId}`, {
          state: {
            clientId: clientId,
          },
        });
      }
    } catch (error) {
      onClose();
      console.error("Failed to submit feedback:", error);
      alert("Failed to submit feedback. Please try again.");
    }
  };

  if (!feedbackGoalShow) return null;

  return (
    <>
      <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <span className='modal-title'>Feedback Form</span>
              <button
                type='button'
                className='btn-close'
                onClick={onClose}
              ></button>
            </div>
            <div className='modal-body'>
              <div key={goalsData.id} className='mb-4'>
                <span>Goal: {goalsData.title}</span>
                <div className='mb-3 d-flex align-items-center'>
                  <p className='mb-0 me-3 text-dark'>Health Check:</p>
                  <div className='form-check form-check-inline'>
                    <input
                      type='radio'
                      name={`healthCheck`}
                      id={`doingGreat`}
                      className='form-check-input'
                      value='Doing Great'
                      checked={healthCheck === "Doing Great"}
                      onChange={(e) => {
                        setHealthCheck(e.target.value);
                      }}
                    />
                    <label htmlFor={`doingGreat`} className='form-check-label'>
                      Doing Great
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      type='radio'
                      name={`healthCheck`}
                      id={`onTarget`}
                      className='form-check-input'
                      value='On Target'
                      checked={healthCheck === "On Target"}
                      onChange={(e) => {
                        setHealthCheck(e.target.value);
                      }}
                    />
                    <label htmlFor={`onTarget`} className='form-check-label'>
                      On Target
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      type='radio'
                      name={`healthCheck`}
                      id={`needsImprovement`}
                      className='form-check-input'
                      value='Needs Improvement'
                      checked={healthCheck === "Needs Improvement"}
                      onChange={(e) => {
                        setHealthCheck(e.target.value);
                      }}
                    />
                    <label
                      htmlFor={`needsImprovement`}
                      className='form-check-label'
                    >
                      Needs Improvement
                    </label>
                  </div>
                </div>
                {healthCheck && (
                  <div className='accordion'>
                    <div className='accordion-item'>
                      <span className='accordion-header'>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapseDescription`}
                          aria-expanded='true'
                          aria-controls={`collapseDescription`}
                        >
                          Provide Description
                        </button>
                      </span>
                      <div
                        id={`collapseDescription`}
                        className='accordion-collapse collapse'
                      >
                        <div className='accordion-body'>
                          <ReactQuill
                            id='description'
                            key={healthCheck}
                            className='react-quill'
                            value={description}
                            modules={{
                              toolbar: [
                                [{ list: "bullet" }],
                                ["bold", "italic", "underline"],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "list",
                              "bold",
                              "italic",
                              "underline",
                              "align",
                            ]}
                            onChange={(value, delta, source) => {
                              if (source === "user") {
                                const tempDiv = document.createElement("div");
                                tempDiv.innerHTML = value;
                                const textContent =
                                  tempDiv.textContent ||
                                  tempDiv.innerText ||
                                  "";

                                if (
                                  textContent.length > MAX_DESCRIPTION_LENGTH
                                ) {
                                  setErrorMessage(
                                    `Description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.`,
                                  );
                                } else {
                                  setErrorMessage("");
                                }
                                if (value.includes("<ul>")) {
                                  setDescription(value);
                                } else {
                                  setDescription(
                                    `<ul><li>${value.replace(/<\/?ul>|<\/?li>/g, "").trim()}</li></ul>`,
                                  );
                                }
                              }
                            }}
                            placeholder={getPlaceholder(healthCheck)}
                            style={{ minHeight: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='modal-footer d-flex justify-content-between align-items-center w-100'>
              <div className='d-flex flex-column align-items-start'>
                {errorMessage && (
                  <div className='text-danger small mb-1'>{errorMessage}</div>
                )}
                {healthCheck === "" && (
                  <div className='text-danger small'>
                    Please select a Health Check option before saving.
                  </div>
                )}
              </div>

              <div>
                <button
                  type='button'
                  className='btn btn-secondary me-2'
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={handleSave}
                  disabled={!!errorMessage || healthCheck === ""}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <span className='modal-title'>Confirm Cancel</span>
                <button
                  type='button'
                  className='btn-close'
                  onClick={handleDismissConfirmation}
                ></button>
              </div>
              <div className='modal-body'>
                <p>
                  Are you sure you want to cancel? Any unsaved changes will be
                  lost.
                </p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={handleDismissConfirmation}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={handleConfirmCancel}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackForGoal;

import { useState } from "react";
import { useGetGoalImpactsQuery } from "features/impacts/impactsSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import MultiTypeChart from "common/charts/MultiTypeChart";

function ImpactsByGoal({ goalId }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  let impactByGoalInfo = [];

  const { data: impactListData, isSuccess: isImpactListSuccess } =
    useGetGoalImpactsQuery(goalId ? goalId : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactListSuccess) {
    impactByGoalInfo = impactListData.data;
  }

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? impactByGoalInfo.length - 1 : prevIndex - 1,
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === impactByGoalInfo.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className='card client-graph-card'>
      {isImpactListSuccess && impactByGoalInfo.length > 0 ? (
        <div className='row mt-2'>
          <div className='col-8 d-flex justify-content-start'>
            <span className='progress-title ms-2'>
              Impact {currentIndex + 1}
            </span>
            <i
              className='bi bi-info-circle info-icon'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={
                isImpactListSuccess && impactByGoalInfo.length > 0
                  ? ` ${impactByGoalInfo[currentIndex].title}`
                  : "No Impact Information Available"
              }
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className='col-4 d-flex justify-content-end'>
            <div className='carousel-container'>
              <div className='carousel-slide'></div>
              <button
                className='carousel-control-prev'
                data-bs-target='#carouselExampleControls'
                type='button'
                data-bs-slide='prev'
                onClick={handlePrevClick}
                disabled={currentIndex === 0}
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next'
                data-bs-target='#carouselExampleControls'
                type='button'
                data-bs-slide='next'
                onClick={handleNextClick}
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Next</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className='card-body mt-2'>
        {isImpactListSuccess && impactByGoalInfo.length > 0 ? (
          <>
            <MultiTypeChart
              impactId={impactByGoalInfo[currentIndex].id}
              impactData={impactByGoalInfo[currentIndex]}
              displayLegend={false}
              flag={true}
            />

            {impactByGoalInfo.length > 1 && (
              <div
                className='d-flex justify-content-center'
                style={{
                  position: "absolute",
                  bottom: "4px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                }}
              >
                {impactByGoalInfo.map((_, i) => (
                  <span
                    key={i}
                    className={`dot mx-1 ${i === currentIndex ? "active" : ""}`}
                    style={{
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      backgroundColor: i === currentIndex ? "#5784bb" : "#ccc",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDotClick(i)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: "30px" }}
          >
            <span style={{ fontSize: "14px", color: "#555" }}>
              No impacts have been created yet. Please add an Impact to get
              started.{" "}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImpactsByGoal;

import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useGetFeedbackByGoalQuery } from "features/goals/goalsSlice";
import { FeedbackForGoal, FeedbackFormEdit } from "features/clients";

function FeedBackRep({ goalId, clientId }) {
  const { data, isSuccess } = useGetFeedbackByGoalQuery(goalId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [descriptionPoints, setDescriptionPoints] = useState([]);
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [feedbackGoalShow, setFeedbackGoalShow] = useState(false);

  useEffect(() => {
    if (isSuccess && data?.data?.length > 0) {
      const feedback = data?.data[0];
      if (feedback?.description) {
        const points = feedback?.description
          .split("\n")
          .map((point) => point.trim().replace(/^•\s*/, ""))
          .filter((point) => point !== "");

        setDescriptionPoints(points);
      } else {
        setDescriptionPoints([]);
      }
    }
  }, [data, isSuccess]);

  const HealthCheck = ({ healthCheck }) => {
    if (!healthCheck) return null;
    const isPositive =
      healthCheck === "Doing Great" || healthCheck === "On Target";
    return (
      <strong>
        <i
          className={`bi ${isPositive ? "bi-hand-thumbs-up" : "bi-hand-thumbs-down"}`}
        ></i>{" "}
        {healthCheck}
      </strong>
    );
  };

  return (
    <div>
      {isSuccess && data?.data?.length > 0 ? (
        <div className='card-body mt-2'>
          <i
            className='bi bi-pencil-square'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Edit'
            style={{
              position: "absolute",
              top: "3px",
              right: "10px",
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => setFeedbackShow(true)}
          ></i>

          <div className='d-flex justify-content-between align-items-center'>
            <span className='focus-score-title mt-2'>
              <HealthCheck healthCheck={data?.data[0]?.health_check} />
            </span>
          </div>

          <div className='description-points'>
            {descriptionPoints.length > 0 ? (
              <ul>
                {descriptionPoints.map((point, index) => (
                  <li key={index}>{point.trim()}</li>
                ))}
              </ul>
            ) : (
              <p>
                No description provided. Please provide a description in the
                Feedback form.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className='card-body text-center'>
          <i
            className='bi bi-plus-circle'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Add Feedback'
            style={{
              position: "absolute",
              top: "3px",
              right: "10px",
              fontSize: "1rem",
              cursor: "pointer",
              color: "black",
              fontWeight: "bold",
            }}
            onClick={() => setFeedbackGoalShow(true)}
          ></i>
          <div
            className='d-flex justify-content-center align-items-center mt-2'
            style={{ minHeight: "30px" }}
          >
            <span style={{ fontSize: "14px", color: "#555" }}>
              No Feedback available for this Goal.
            </span>
          </div>
        </div>
      )}
      {feedbackShow && (
        <FeedbackFormEdit
          clientId={clientId}
          goalId={goalId}
          feedbackShow={feedbackShow}
          onClose={() => setFeedbackShow(false)}
        />
      )}
      {feedbackGoalShow && (
        <FeedbackForGoal
          clientId={clientId}
          goalId={goalId}
          feedbackGoalShow={feedbackGoalShow}
          onClose={() => setFeedbackGoalShow(false)}
        />
      )}
    </div>
  );
}

export default FeedBackRep;

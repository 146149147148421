import { apiSlice } from "app/api";

const clientSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVendorClients: build.query({
      query: (vendoraccountid) => `accounts/clients/${vendoraccountid}`,
      providesTags: ["Account"],
    }),
    getConsultantClients: build.query({
      query: (consultantId) => `accounts/consultantclients/${consultantId}`,
      providesTags: ["Account"],
    }),
    getClientDetails: build.query({
      query: (clientId) => `accounts/${clientId}`,
      providesTags: ["Account"],
    }),
    getClientFeedbackDetails: build.query({
      query: (clientId) => `goals/feedback_account/${clientId}`,
      providesTags: ["Account"],
    }),
    addClientFeedback: build.mutation({
      query: ({ ...body }) => ({
        url: `goals/feedback_account`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Account"],
    }),
    updateClient: build.mutation({
      query: ({ clientId, body }) => {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (body[key] !== null && body[key] !== undefined) {
            if (key === "logo" && body[key] instanceof File) {
              formData.append("logo", body[key]);
            } else {
              formData.append(key, body[key]);
            }
          }
        });
        return {
          url: `/accounts/${clientId}`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Account"],
    }),
    deleteClient: build.mutation({
      query: (clientId) => ({
        url: `/accounts/${clientId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Account"],
    }),
    addClient: build.mutation({
      query: ({ body }) => {
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (body[key] !== null && body[key] !== undefined) {
            if (key === "logo" && body[key] instanceof File) {
              formData.append("logo", body[key]);
            } else {
              formData.append(key, body[key]);
            }
          }
        });
        return {
          url: `/accounts/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Account"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVendorClientsQuery,
  useGetConsultantClientsQuery,
  useGetClientDetailsQuery,
  useGetClientFeedbackDetailsQuery,
  useAddClientFeedbackMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useAddClientMutation,
} = clientSlice;

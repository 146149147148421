import { apiSlice } from "app/api";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: () => `/userservice/users`,
      providesTags: ["User"],
    }),
    getAllUsers: builder.query({
      query: () => `/users`,
      providesTags: ["User"],
    }),
    getUsers: builder.query({
      query: () => `/users`,
    }),
    registerUser: builder.mutation({
      query: (userData) => ({
        url: `/userservice/register/`,
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    inviteUser: builder.mutation({
      query: (inviteUserInfo) => ({
        url: `/userservice/invitation/`,
        method: "POST",
        body: inviteUserInfo,
      }),
    }),
    getUserDetails: builder.query({
      query: (userId) => `/userservice/user_details/${userId}`,
    }),

    updateUser: builder.mutation({
      query: (updatedUserData) => ({
        url: `/userservice/user_details/${updatedUserData.id}`,
        method: "PATCH",
        body: updatedUserData,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserListQuery,
  useGetAllUsersQuery,
  useGetUsersQuery,
  useRegisterUserMutation,
  useInviteUserMutation,
  useGetUserDetailsQuery,
  useUpdateUserMutation,
} = userApiSlice;

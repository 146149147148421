import { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  useGetFeedbackByGoalQuery,
  useUpdateGoalFeedbackMutation,
} from "features/goals/goalsSlice";
import { skipToken } from "@reduxjs/toolkit/query";

function FeedbackFormEdit({ goalId, feedbackShow, onClose }) {
  const [formState, setFormState] = useState([]);
  const hasErrors = formState.some((goal) => goal.error);

  const { data: feedbackData, isSuccess } = useGetFeedbackByGoalQuery(
    goalId ? goalId : skipToken,
    {
      refetchOnMountOrArgChange: 1,
    },
  );

  const [updateGoalFeedback] = useUpdateGoalFeedbackMutation({
    refetchOnMountOrArgChange: true,
  });

  const getPlaceholder = (healthCheck) => {
    switch (healthCheck) {
      case "Doing Great":
        return "What key milestones or results have you achieved so far? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that have advanced this goal. List any measurable results.";
      case "On Target":
        return "What progress have you made so far toward achieving your goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Highlight any significant impacts/initiatives/projects that are meeting expectations. List any measurable results.";
      case "Needs Improvement":
        return "What specific challenges have been holding you back from achieving this goal? Reflect on the Initiatives, Impacts, Projects, and Tasks related to this goal. Are there any areas of improvement? Which impacts/initiatives/projects are not meeting expectations? List any measurable results.";
      default:
        return "";
    }
  };

  const convertTextToHtml = (text) => {
    const lines = text.split("\n");
    const listItems = lines
      .map((line) => line.trim())
      .filter((line) => line.startsWith("•"))
      .map((line) => `<li>${line.substring(1).trim()}</li>`)
      .join("");

    return listItems ? `<ul>${listItems}</ul>` : text;
  };

  useEffect(() => {
    if (isSuccess && feedbackData?.data) {
      const fetchedFeedbacks = feedbackData.data.map((feedback) => ({
        id: feedback.id,
        goal_id: feedback.goal,
        goal: feedback.goal_title,
        healthCheck: feedback.health_check || "",
        description: convertTextToHtml(feedback.description || ""),
      }));
      setFormState(fetchedFeedbacks);
    }
  }, [isSuccess, feedbackData]);

  const extractPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const listItems = tempDiv.querySelectorAll("li");
    if (listItems.length > 0) {
      return Array.from(listItems)
        .map((li) => `• ${li.innerText.trim()}`)
        .join("\n");
    }
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleInputChange = (index, field, value) => {
    setFormState((prevState) => {
      const updatedState = [...prevState];
      if (field === "description") {
        const plainText = extractPlainText(value);
        updatedState[index].error =
          plainText.length > 500
            ? "Description cannot exceed 500 characters."
            : "";
      }
      updatedState[index][field] = value;
      return updatedState;
    });
  };

  const handleSave = async () => {
    try {
      const updates = {
        description: extractPlainText(formState[0]?.description || ""),
        health_check: formState[0]?.healthCheck || "",
      };
      const result = await updateGoalFeedback({
        goalId: goalId,
        body: updates,
      }).unwrap();

      console.log("Update Result:", result);
      onClose();
    } catch (error) {
      console.error("Failed to update feedback:", error);
    }
  };

  if (!feedbackShow) return null;

  return (
    <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
      <div className='modal-dialog modal-lg' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <span className='modal-title'>Feedback Form</span>
            <button
              type='button'
              className='btn-close'
              onClick={onClose}
            ></button>
          </div>
          <div className='modal-body'>
            {!feedbackData ||
            !feedbackData.data ||
            feedbackData.data.length === 0 ? (
              <p className='text-center text-muted'>
                No feedback data available.
              </p>
            ) : (
              formState.map((goal, index) => (
                <div key={goal.id} className='mb-4'>
                  <span>
                    Goal {index + 1}: {goal.goal}
                  </span>
                  <div className='mb-3 d-flex align-items-center'>
                    <p className='mb-0 me-3 text-dark'>Health Check:</p>
                    {["Doing Great", "On Target", "Needs Improvement"].map(
                      (status) => (
                        <div
                          className='form-check form-check-inline'
                          key={status}
                        >
                          <input
                            type='radio'
                            name={`healthCheck${goal.id}`}
                            id={`${status}${goal.id}`}
                            className='form-check-input'
                            value={status}
                            checked={goal.healthCheck === status}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "healthCheck",
                                e.target.value,
                              )
                            }
                          />
                          <label
                            htmlFor={`${status}${goal.id}`}
                            className='form-check-label'
                          >
                            {status}
                          </label>
                        </div>
                      ),
                    )}
                  </div>
                  <div className='accordion'>
                    <div className='accordion-item'>
                      <span className='accordion-header'>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapseDescription${goal.id}`}
                          aria-expanded='true'
                          aria-controls={`collapseDescription${goal.id}`}
                        >
                          Provide Description
                        </button>
                      </span>
                      <div
                        id={`collapseDescription${goal.id}`}
                        className='accordion-collapse collapse show'
                      >
                        <div className='accordion-body'>
                          <ReactQuill
                            key={goal.healthCheck}
                            value={goal.description}
                            modules={{
                              toolbar: [
                                [{ list: "bullet" }],
                                ["bold", "italic", "underline"],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                            onChange={(value) =>
                              handleInputChange(index, "description", value)
                            }
                            placeholder={getPlaceholder(goal.healthCheck)}
                            style={{ minHeight: "200px" }}
                          />
                          {goal.error && (
                            <div className='text-danger mt-1'>{goal.error}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className='modal-footer mt-3'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-primary'
              disabled={hasErrors}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackFormEdit;

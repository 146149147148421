import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";
import drawerReducer from "features/ui/side-nav/drawerSlice";
import authReducer from "features/auth/authSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { countryApiSlice } from "app/api/countryApiSlice";
import inactiveSessionMiddleware from "./api/middleware/inactiveSessionMiddleware";

const persistConfig = {
  key: "counter",
  storage,
};

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [countryApiSlice.reducerPath]: countryApiSlice.reducer,
  auth: authReducer,
  drawer: drawerReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, countryApiSlice.middleware, inactiveSessionMiddleware),
});

setupListeners(store.dispatch);

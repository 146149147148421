import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProjectQuery } from "./projectsSlice";
import {
  useGetProjectAttachmentsQuery,
  useAddProjectAttachmentsMutation,
  useDeleteAttachmentMutation,
} from "common/attachments/attachmentSlice";
import { store } from "app/store";
import Attachment from "common/attachments/Attachment";
import FormHandler from "common/formHandler/FormHandler";
import { useDeleteProjectMutation } from "./projectsSlice";
import { DeleteDialog } from "common";
import { useGetHierarchyByProjectQuery } from "features/projects/projectsSlice";
import { Hierarchy, Dates } from "common";
import { skipToken } from "@reduxjs/toolkit/query";

function ProjectDetail() {
  let projectInfo = [];
  let attachmentsInfo = [];
  let consultantId;
  let projectHierarchy = [];

  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = (location.state && location.state) || {};
  const [inventoryId, setInventoryId] = useState(1);

  const [model, setModel] = useState(false);

  const hideDialog = () => {
    setModel(false);
    navigate("/projects");
  };

  const [projectDetails, setProjectDetails] = useState({
    projectId: "",
    projectName: "",
  });

  const DELETE_DIALOG_TITLE = "Project: Delete";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete "${projectDetails.projectName}" project?`;

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  const [deleteProject] = useDeleteProjectMutation();

  const { data: projectData, isSuccess: isGetProjectsSuccess } =
    useGetProjectQuery(projectId ? projectId : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  if (isGetProjectsSuccess) {
    projectInfo = projectData.data;
  }

  const projectAttachmentsData = useGetProjectAttachmentsQuery(
    projectId ? projectId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (projectAttachmentsData.isSuccess) {
    attachmentsInfo = projectAttachmentsData.data.attachments;
  }

  const [addProjectAttachemnts] = useAddProjectAttachmentsMutation({
    refetchOnMountOrArgChange: true,
  });

  const [deleteAttachemnt] = useDeleteAttachmentMutation({
    refetchOnMountOrArgChange: true,
  });

  const handleDeleteAttachment = async (attachmentId) => {
    await deleteAttachemnt(attachmentId);
  };

  const deleteProjectDialog = (projectId, projectName) => {
    setProjectDetails({ projectId: projectId, projectName: projectName });
    return setModel(true);
  };
  const { data: projectHierarchyData, isSuccess: isProjectHierarchySuccess } =
    useGetHierarchyByProjectQuery(projectId, {
      refetchOnMountOrArgChange: true,
    });

  if (isProjectHierarchySuccess && projectHierarchyData.data) {
    projectHierarchy = projectHierarchyData.data;
  }
  if (store.getState().auth.userData) {
    consultantId = store.getState().auth.userData.id;
  }
  const handleAddAttachment = async (formData) => {
    formData.append("project_id", projectId);
    formData.append("user_id", consultantId);
    await addProjectAttachemnts(formData);
  };

  const renderInventoryContent = () => {
    switch (inventoryId) {
      case 1:
        return <Hierarchy hierarchy={projectHierarchy} />;
      case 2:
        return (
          <Dates
            created={projectInfo.created_on_date}
            started={projectInfo.start_date}
            ended={projectInfo.end_date}
            lastModified={projectInfo.modified_on_date}
          />
        );
      // case 3:
      //   return (
      //     <Budget
      //       allocatedBudget={projectInfo.allocated_budget}
      //       currentSpend={projectInfo.spend_budget}
      //     />
      //   );
      case 5:
        return (
          <Attachment
            attachmentsInfo={attachmentsInfo}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        );
      default:
        return "placeholder text";
    }
  };

  return (
    <div className='col-4'>
      <div className='card p-4'>
        <div className='card-body d-flex flex-column'>
          <div className='row'>
            <div className='col-1'>
              <i className='bi bi-briefcase icon-title'></i>
            </div>
            <div className='col'>
              <h1 className='project-title'>{projectInfo.title}</h1>
            </div>
          </div>
          <div className='project-status'>
            {projectInfo.stage === "Not-Started" ? (
              <span className='badge text-bg-danger small'>
                {projectInfo.stage}
              </span>
            ) : projectInfo.stage === "In-Progress" ? (
              <span className='badge text-bg-warning small'>
                {projectInfo.stage}
              </span>
            ) : (
              <span className='badge text-bg-success small'>
                {projectInfo.stage}
              </span>
            )}
            <div>
              <FormHandler
                formInputs={{
                  formType: "Projects",
                  formAction: "Edit",
                  itemDetails: {
                    id: projectInfo.id,
                  },
                }}
              />
              <span className='action-item'>
                <i
                  className='bi bi-trash-fill'
                  data-bs-placement='top'
                  title='Delete'
                  onClick={() =>
                    deleteProjectDialog(projectInfo.id, projectInfo.title)
                  }
                ></i>
              </span>
            </div>
          </div>
          <p className='project-description'>{projectInfo.description}</p>
          <div className='project-icon-group'>
            <button
              className='btn-basic'
              onClick={() => handleChange(1)}
              data-toggle='tooltip'
              data-placement='top'
              title='Hierarchy'
            >
              <i
                className={`bi bi-diagram-3 ${
                  inventoryId === 1 ? "active" : ""
                }`}
              ></i>
            </button>
            <button
              className='btn-basic'
              onClick={() => handleChange(2)}
              data-toggle='tooltip'
              data-placement='top'
              title='Calendar'
            >
              <i
                className={`bi bi-calendar-date ${
                  inventoryId === 2 ? "active" : ""
                }`}
              ></i>
            </button>

            <button
              className='btn-basic'
              onClick={() => handleChange(5)}
              data-toggle='tooltip'
              data-placement='top'
              title='Attachment'
            >
              <i
                className={`bi bi-paperclip ${
                  inventoryId === 5 ? "active" : ""
                }`}
              ></i>
            </button>
          </div>
          <div id='inventory-content' className='mt-4'>
            {renderInventoryContent()}
          </div>
        </div>
      </div>
      {model === true ? (
        <DeleteDialog
          dialogTitle={DELETE_DIALOG_TITLE}
          descriptionText={DELETE_DIALOG_MESSAGE}
          hide={() => hideDialog()}
          handleDelete={() => deleteProject(projectDetails.projectId)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ProjectDetail;

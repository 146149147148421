import { BarGraph } from "features/impacts";
import { useState } from "react";

function InitiativeDropDown({ impactsInfo }) {
  let allimpactIds = [];
  let defaultImpact = "0";
  let j = 0;
  for (let i = 0; i < impactsInfo.length; i++) {
    allimpactIds[j] = impactsInfo[i].id;
    j++;
  }
  const [dropDownId, setDropDownId] = useState(defaultImpact);

  const hasCumulativeData = impactsInfo.some(
    (impact) => parseFloat(impact.overall_cumulative_value) > 0,
  );

  const hasAnyZeroCumulative = impactsInfo.some(
    (impact) => parseFloat(impact.overall_cumulative_value) === 0,
  );

  const filteredImpactIds = hasAnyZeroCumulative
    ? impactsInfo
        .filter((impact) => parseFloat(impact.overall_cumulative_value) > 0)
        .map((impact) => impact.id)
    : allimpactIds;

  const handleChange = (e) => {
    setDropDownId(e);
  };

  return (
    <div>
      <div className='row mb-4'>
        <div className='col'>
          <div className='card'>
            <div className='card-header'>
              <select
                id='impact-select'
                className='form-select form-select-sm'
                onChange={(e) => handleChange(e.target.value)}
                defaultValue={defaultImpact}
              >
                <option value='0'>All</option>
                {impactsInfo.map((impact) => {
                  return <option value={impact.id}>{impact.title}</option>;
                })}
              </select>
            </div>
            <div className='card-body'>
              {dropDownId === "0" ? (
                hasCumulativeData ? (
                  <>
                    {filteredImpactIds.map((impactId) => (
                      <div key={impactId}>
                        <BarGraph impactId={impactId} />
                      </div>
                    ))}
                    {hasAnyZeroCumulative && (
                      <>
                        <div className='card shadow-sm border-0 mt-3'>
                          <div className='text-center text-muted mt-3'>
                            <p>
                              No data points available for some impacts. Please
                              add data points to view the graph for these
                              impacts.
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className='text-center text-muted'>
                    <p>
                      No data points available for any impacts. Please add data
                      points to view the graph for all impacts .
                    </p>
                  </div>
                )
              ) : (
                <BarGraph impactId={dropDownId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitiativeDropDown;

import JoditEditor from "jodit-react";
import { useState, useRef, useMemo } from "react";
import DOMPurify from "dompurify";

function Comment({ comments, handleCommentSubmit }) {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleChange = async () => {
    const editorContent = editor.current?.value || "";
    const formData = new FormData();
    formData.append("text", editorContent);
    await handleCommentSubmit(formData);
    setContent("");
  };
  const sanitizeComment = (text) => {
    return { __html: DOMPurify.sanitize(text) };
  };

  const config = useMemo(
    () => ({
      hidePoweredByJodit: true,
    }),
    [],
  );

  return (
    <>
      <div className='row mb-4'>
        <div className='col'>
          <JoditEditor
            ref={editor}
            value={content}
            onChange={(newContent) => setContent(newContent)}
            config={config}
          />
          <button
            type='submit'
            className='btn btn-primary text-white my-2'
            onClick={handleChange}
          >
            Save
          </button>
        </div>
      </div>

      <div className='row mb-4'>
        {comments.map((comment) => (
          <div className='notes' key={comment.id}>
            <span>
              {new Date(comment.created_on_date).toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              }).replace(",", "")}
              - {comment.created_by}
            </span>

            <p dangerouslySetInnerHTML={sanitizeComment(comment.text)}></p>
          </div>
        ))}
      </div>
    </>
  );
}

export default Comment;

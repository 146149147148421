export const formatPhoneNumber = (value) => {
  if (!value) return;
  const digits = value.replace(/\D/g, "");
  if (digits.length === 0) {
    return ""; // No input
  } else if (digits.length <= 3) {
    return `(${digits}`;
  } else if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`; // Area code and first 3 digits
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`; // Full number
  }
};

import { apiSlice } from "app/api";

const impactsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getImpact: build.query({
      query: (impactId) => `/impacts/${impactId}`,
      providesTags: ["Impact"],
    }),
    getConsultantImpacts: build.query({
      query: (ownerConsultantId) =>
        `impacts/impactlist_consultant/${ownerConsultantId}`,
      providesTags: ["Impact"],
    }),
    getConsultantImpactsDp: build.query({
      query: (ownerConsultantId) =>
        `impacts/impactdatapointlist_consultant/?owner_consultant_id=${ownerConsultantId}`,
      providesTags: ["Impact"],
    }),
    getAccountImpactsDp: build.query({
      query: (clientid) =>
        `impacts/impactdatapointlist_account/?vendor_account_id=${clientid}`,
      providesTags: ["Impact"],
    }),
    getCustomerImpacts: build.query({
      query: (ownerCustomerId) =>
        `/impacts/impactlist_customer/${ownerCustomerId}`,
      providesTags: ["Impact"],
    }),
    getAccountImpacts: build.query({
      query: (accountId) => `/impacts/impactlist_account/${accountId}`,
      providesTags: ["Impact"],
    }),
    getVendorImpacts: build.query({
      query: (vendorAccountId) =>
        `/impacts/impactlist_vendor/${vendorAccountId}`,
      providesTags: ["Impact"],
    }),
    getGoalImpacts: build.query({
      query: (goalId) => `/impacts/impactlist_goal/${goalId}`,
      providesTags: ["Impact"],
    }),
    getInitiativeImpacts: build.query({
      query: (Id) => `/impacts/impactlist_initiative/${Id}`,
      providesTags: ["Impact"],
    }),
    getImpactsByStage: build.query({
      query: (goalId) => `/impacts/impactlist_goal/${goalId}`,
      providesTags: ["Impact"],
    }),
    deleteImpact: build.mutation({
      query: (impactId) => ({
        url: `/impacts/${impactId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Impact"],
    }),
    getImpactHistory: build.query({
      query: (impactId) => `/impacts/impact_history/${impactId}`,
      providesTags: ["Impact"],
    }),
    getImpactsHistoryByInitiative: build.query({
      query: (initiativeId) => `/impacts/history/initiative/${initiativeId}`,
      providesTags: ["Impact"],
    }),
    getImpactHistoryByConsultant: build.query({
      query: (consultantId) => `/impacts/history/${consultantId}`,
      providesTags: ["Impact"],
    }),
    getImpactDataPointsByImpactId: build.query({
      query: (impactId) => `/impacts/datapointlist/?impact_id=${impactId}`,
      providesTags: ["Impact"],
    }),
    addImpact: build.mutation({
      query: ({ ...body }) => ({
        url: `/impacts/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    addImpactDataPoint: build.mutation({
      query: ({ ...body }) => ({
        url: `/impacts/dp/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    updateImpactDataPoint: build.mutation({
      query: ({ dataPointId, ...body }) => ({
        url: `/impacts/dp/${dataPointId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    addGraphLabel: build.mutation({
      query: ({ ...body }) => ({
        url: `/impacts/graphlabel/`,
        method: "POST",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    getGraphLabel: build.query({
      query: (impactId) => `/impacts/graphlabel_impact/${impactId}`,
      providesTags: ["Impact"],
    }),
    updateGraphLabel: build.mutation({
      query: ({ graphLabelId, ...body }) => ({
        url: `/impacts/graphlabel/${graphLabelId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    updateImpact: build.mutation({
      query: ({ impactId, ...body }) => ({
        url: `/impacts/${impactId}`,
        method: "PATCH",
        ...body,
      }),
      invalidatesTags: ["Impact"],
    }),
    getImpactById: build.mutation({
      query: (impactId) => ({ url: `/impacts/${impactId}`, method: "Get" }),
      providesTags: ["Impact"],
    }),
    getImpactsFilter: build.mutation({
      query: ({ search = "" }) => {
        const params = new URLSearchParams();
        if (search) params?.append("search", search);
        return {
          url: `/impacts?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Initiative"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetImpactQuery,
  useGetConsultantImpactsQuery,
  useGetConsultantImpactsDpQuery,
  useGetAccountImpactsDpQuery,
  useGetCustomerImpactsQuery,
  useGetAccountImpactsQuery,
  useGetVendorImpactsQuery,
  useGetGoalImpactsQuery,
  useGetInitiativeImpactsQuery,
  useGetImpactsByStageQuery,
  useDeleteImpactMutation,
  useGetImpactHistoryQuery,
  useGetImpactsHistoryByInitiativeQuery,
  useGetImpactHistoryByConsultantQuery,
  useGetImpactDataPointsByImpactIdQuery,
  useUpdateImpactDataPointMutation,
  useAddImpactMutation,
  useAddImpactDataPointMutation,
  useAddGraphLabelMutation,
  useGetGraphLabelQuery,
  useUpdateGraphLabelMutation,
  useUpdateImpactMutation,
  useGetImpactByIdMutation,
  useGetImpactsFilterMutation,
} = impactsSlice;

import { logOut } from "features/auth/authSlice";
import { toast } from "react-toastify";

let lastApiCallTime = null;

const inactiveSessionMiddleware = store => next => action => {
    if(action.type === logOut.type) {
        return next(action);
    }

  const currentTime = new Date().getTime();
  if (lastApiCallTime) {
    const timeDifference = currentTime - lastApiCallTime;
    // after 15 minutes of inactivity, log out the user
    if(timeDifference > 900000) {
        console.log("Session expired (15 min). Logging out.");
        toast.error("Your session has expired because 15 min of inactivity. Please log in again.");
        store.dispatch(logOut());
    }
  }
  lastApiCallTime = currentTime;
  return next(action);
};

export default inactiveSessionMiddleware;
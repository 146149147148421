import { useGetGoalInitiativesQuery } from "features/initiatives/initiativesSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";

function InitiativeProgressBar({ goalId }) {
  let initData = [];

  const { data, isSuccess } = useGetGoalInitiativesQuery(
    goalId ? goalId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  if (isSuccess) {
    initData = data.data;
  }
  const totalSlides = Math.ceil(initData.length / 3);

  const setProgressValue = (stage) => {
    if (stage === "Not-Started") {
      return 0;
    } else if (stage === "In-Progress") {
      return 50;
    } else {
      return 100;
    }
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const handlePrev = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
  };
  const handleNext = () => {
    if (currentIndex + 3 < initData.length) {
      setCurrentIndex(currentIndex + 3);
    }
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex * 3);
  };

  const calculateTimePassed = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalTime = end - start;
    const elapsedTime = now - start;
    if (totalTime <= 0) {
      return 100;
    } else {
      const percentage = (elapsedTime / totalTime) * 100;
      return Math.min(Math.max(percentage, 0), 100);
    }
  };

  return (
    <div>
      {initData && initData.length > 0 ? (
        <div className='card-body mt-2'>
          <div className='carousel-container'>
            <div className='carousel-slide'></div>
            <button
              className='carousel-control-prev'
              data-bs-target='#carouselExampleControls'
              type='button'
              data-bs-slide='prev'
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              data-bs-target='#carouselExampleControls'
              type='button'
              data-bs-slide='next'
              onClick={handleNext}
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
          <div>
            {initData
              .slice(currentIndex, currentIndex + 3)
              .map((initiative, i) => {
                const progress = setProgressValue(initiative.stage);
                const percentageTimePassed = calculateTimePassed(
                  initiative.start_date,
                  initiative.end_date,
                );
                return (
                  <div className='progress-container'>
                    <div className='progress-title mb-1 mt-2'>
                      Initiative {currentIndex + i + 1}
                      <i
                        class='bi bi-info-circle info-icon'
                        data-toggle='tooltip'
                        data-placement='top'
                        title={initiative.title}
                      ></i>
                    </div>
                    <div className='progress-labels'>
                      <span>NOT STARTED</span>
                      <span> COMPLETED</span>
                    </div>
                    <div
                      className='progress'
                      style={{ height: "15px", borderRadius: "0" }}
                    >
                      <div
                        className='progress-bar'
                        role='progressbar'
                        style={{ width: "100%" }}
                        aria-valuenow='100'
                        aria-valuemin='0'
                        aria-valuemax='100'
                      ></div>
                      <i
                        class='bi bi-caret-up-fill progress-caret'
                        style={{ left: `${progress}%` }}
                      ></i>
                      {percentageTimePassed >= 25 &&
                      initiative.stage === "Not-Started" ? (
                        <svg
                          style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "20px",
                            height: "20px",
                            marginTop: "14px",
                          }}
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#FF0000'
                          strokeWidth='3'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                            <title>{`${percentageTimePassed.toFixed(1)}% time passed; Initiative still not started.`}</title>

                          <polygon points='12,4 4,16 20,16 12,4' />
                        </svg>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* Pagination dots */}
          <div
            className='d-flex justify-content-center'
            style={{
              position: "absolute",
              bottom: "5px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
            }}
          >
            {totalSlides > 1 &&
              Array.from({ length: totalSlides }).map((_, index) => (
                <span
                  key={index}
                  type='button'
                  className={`dot mx-1 
                  ${index === Math.floor(currentIndex / 3) ? "active" : ""}`}
                  aria-current={
                    index === Math.floor(currentIndex / 3) ? "true" : "false"
                  }
                  style={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    backgroundColor:
                      index === Math.floor(currentIndex / 3)
                        ? "#5784bb"
                        : "#ccc",
                    cursor: "pointer",
                  }}
                  onClick={() => goToSlide(index)}
                />
              ))}
          </div>
        </div>
      ) : (
        <div
          className='card-body d-flex justify-content-center align-items-center'
          style={{ minHeight: "76px", paddingTop: "25px" }}
        >
          <span style={{ fontSize: "14px", color: "#555" }}>
            No initiatives have been created yet. Please add an Initiative to
            get started.
          </span>
        </div>
      )}
    </div>
  );
}

export default InitiativeProgressBar;

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { formatPhoneNumber } from "common/helpers/formatPhoneNumber";
import { useRegisterUserMutation } from "features/user/userSlice";
function RegisterUser() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //Password Strength
  const getPasswordStrength = (password) => {
    if (!password) return "";
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const mediumRegex = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    if (strongRegex.test(password)) return "Strong";

    if (mediumRegex.test(password)) return "Medium";
    return "weak";
  };
  //track change
  const password = watch("password");
  const passwordStrength = getPasswordStrength(password);
  const handlePhoneChange = (event) => {
    setValue("phoneNumber", formatPhoneNumber(event.target.value));
  };
  const [addUser] = useRegisterUserMutation({
    refetchOnMountOrArgChange: true,
  });

  const onSubmit = async (data) => {
    const { confirmPassword, phoneNumber, ...formData } = data;
    const phone = phoneNumber?.replace(/\D/g, "");
    const finalData = { ...formData, organization: "1", phoneNumber: phone };
    try {
      await addUser(finalData).unwrap();
      alert("User has been registered successfully");
      navigate("/login");
    } catch (err) {
      alert(`The ${err.data?.data?.email}` || "An error has occurred");
    }
  };
  return (
    <div className='div'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <div className='card p-4 shadow'>
            <h5 className='text-align-left'> User Registration</h5>
            <div className='position-relative'>
              <hr
                className='border-secondary border-2 my-3'
                style={{
                  width: "calc(100% + 3rem)",
                  marginLeft: "-1.5rem",
                  marginRight: "-1.5rem",
                }}
              />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label'>
                    First Name <span className='text-danger'>*</span>
                  </label>
                  <input
                    {...register("first_name", {
                      required: "First Name is required",
                      maxLength: {
                        value: 50,
                        message: "First Name can not exceed 50 characters",
                      },
                    })}
                    className='form-control'
                    placeholder='First Name'
                  />
                  {errors.first_name && (
                    <small className='text-danger'>
                      {" "}
                      {errors.first_name.message}
                    </small>
                  )}
                </div>
                <div className='col'>
                  <label className='form-label'>
                    Last Name <span className='text-danger'>*</span>
                  </label>

                  <input
                    {...register("last_name", {
                      required: "Last Name is required",
                      maxLength: {
                        value: 50,
                        message: "Last Name can not exceed 50 characters",
                      },
                    })}
                    className='form-control'
                    placeholder='Last Name'
                  />
                  {errors.last_name && (
                    <small className='text-danger'>
                      {" "}
                      {errors.last_name.message}
                    </small>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col'>
                  <label className='form-label'>
                    Title <span className='text-danger'>*</span>
                  </label>

                  <input
                    {...register("title", {
                      required: "Title is required",
                      maxLength: {
                        value: 50,
                        message: "Title can not exceed 50 characters",
                      },
                    })}
                    className='form-control'
                    placeholder='Title'
                  />
                  {errors.title && (
                    <small className='text-danger'>
                      {" "}
                      {errors.title.message}
                    </small>
                  )}
                </div>
              </div>
              <br />
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label'>
                    Email <span className='text-danger'>*</span>
                  </label>

                  <input
                    {...register("email", {
                      required: "Email is required",
                      maxLength: {
                        value: 50,
                        message: "Email can not exceed 50 characters",
                      },
                    })}
                    className='form-control'
                    placeholder='Email'
                  />
                  {errors.email && (
                    <small className='text-danger'>
                      {" "}
                      {errors.email.message}
                    </small>
                  )}
                </div>
                <div className='col'>
                  <label className='form-label'>Phone number</label>

                  <input
                    {...register("phoneNumber", {
                      maxLength: {
                        value: 14,
                        message: "Phone number can not exceed 10 digits",
                      },
                    })}
                    className='form-control'
                    placeholder='Phone Number'
                    onChange={handlePhoneChange}
                  />
                  {errors.phoneNumber && (
                    <small className='text-danger'>
                      {" "}
                      {errors.phoneNumber.message}
                    </small>
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <label className='form-label'>
                    Password <span className='text-danger'>*</span>
                  </label>
                  <div className='position-relative'>
                    <input
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be atleast 8 characters",
                        },
                        pattern: { value: /^(?=.*\d)(?=.*[@$!%*?&])/ },
                      })}
                      className='form-control pe-5'
                      placeholder='Password'
                    />
                    <i
                      className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} position-absolute`}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                    {errors.password && (
                      <small
                        className='text-danger small'
                        style={{ position: "absolute", bottom: "-20px" }}
                      >
                        {" "}
                        {errors.password.message}
                      </small>
                    )}
                    {password && (
                      <small
                        className={`text-${passwordStrength === "Strong" ? "success" : passwordStrength === "Medium" ? "warning" : "danger"}`}
                      >
                        Strength: {passwordStrength}
                      </small>
                    )}
                  </div>
                </div>
                <div className='col'>
                  <label className='form-label'>
                    Confirm Password<span className='text-danger'>*</span>
                  </label>
                  <div className='position-relative'>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords do not match",
                      })}
                      className='form-control pe-5'
                      placeholder='Confirm Password'
                    />
                    <i
                      className={`bi ${showConfirmPassword ? "bi-eye" : "bi-eye-slash"} position-absolute`}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {" "}
                    </i>

                    {errors.confirmPassword && (
                      <small className='text-danger'>
                        {" "}
                        {errors.confirmPassword.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className='text-center mt-3'>
                <button type='submit' className='btn btn-primary align-center'>
                  Register
                </button>
              </div>
              <div className='text-center mt-3'>
                <p className='text-center mt-3 text-body'>
                  Already a user?{" "}
                  <a href='/login' className='text-primary fw-bold text-body'>
                    Login here
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterUser;

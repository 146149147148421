import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Chart({ labels, data1, data2, title, graphDataX, graphDataY, trackingFrequencyLabel }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: graphDataX,
          font: {
            size: 14,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: graphDataY,
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: `${trackingFrequencyLabel} Actual`,
        data: data1,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: `${trackingFrequencyLabel} Target`,
        data: data2,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="col">
      <Bar options={options} data={data} />
    </div>
  );
}

export default Chart;

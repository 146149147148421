import { Bar } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  useGetGraphLabelQuery,
  useGetImpactDataPointsByImpactIdQuery,
} from "features/impacts/impactsSlice";
import { Button } from "react-bootstrap";
import { useState } from "react";
import AddEditModal from "features/impacts/AddEditModal";
import { getMonthShorthand } from "common/utils/GetMontShorthand";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const MultiTypeChart = ({ impactId, impactData, displayLegend, flag }) => {
  let data = {
    targetData: [],
    actualData: [],
    labelData: [],
    overallTargetValueData: [],
    cumulativeData: [],
  };

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const parseDataPoints = (values) => {
    if (!values.length) {
      return;
    }

    const recentValues = values.slice(-6);
    let overallTargetValue = impactData.overall_target_value;

    recentValues.forEach((value, idx) => {
      data.targetData.push(parseFloat(value.target_value));
      data.actualData.push(parseFloat(value.actual_value));
      let label = value.title;
      if (
        trackingFrequencyLabel === "Quarterly"
      ) {
        label = `${label} ${value.year}`;
      }else if (
        trackingFrequencyLabel === "Monthly"
      ){
        label = `${getMonthShorthand(label)} ${value.year}`;
      }

      data.labelData.push(label);
      data.overallTargetValueData.push(parseFloat(overallTargetValue));
      data.cumulativeData.push(parseFloat(value.cumulative_value));
    });
  };

  let graphDataX;
  let graphDataY;

  //get graph labels
  const { data: graphLabelData, isSuccess: isGraphLabelDataSuccess } =
    useGetGraphLabelQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isGraphLabelDataSuccess && Array.isArray(graphLabelData.data)) {
    const relevantGraph = graphLabelData.data.find(
      (entry) => entry.graph_name === "Health Check",
    );
    if (relevantGraph) {
      graphDataX = relevantGraph.label_x_axis;
      graphDataY = relevantGraph.label_y_axis;
    }
  }

  const trackingFrequencyLabel = impactData?.tracking_frequency || "";

  console.log("Tracking Frequency Label: ", trackingFrequencyLabel);

  const chartData = {
    labels: data.labelData,
    datasets: [
      {
        label: `${trackingFrequencyLabel} Actual`,
        type: "bar",
        data: data.actualData,
        backgroundColor: "rgba(194, 225, 244, 0.8)",
        borderColor: "rgba(194, 225, 244, 1)",
        borderWidth: 1,
      },
      {
        label: `${trackingFrequencyLabel} Target`,
        type: "bar",
        data: data.targetData,
        backgroundColor: "rgba(87,132,187,0.6)",
        borderColor: "rgba(87,132,187,1)",
        borderWidth: 1,
      },
      {
        label: "Cumulative",
        type: "line",
        data: data.cumulativeData,
        backgroundColor: "rgba(255,99,132,0.4)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
      },
      {
        label: "Overall Target",
        type: "line",
        data: data.overallTargetValueData,
        backgroundColor: "rgba(0,0,0,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 10,
      },
    },
    plugins: {
      legend: {
        display: displayLegend !== false,
        position: "bottom",
        labels: {
          usePointStyle: false,
          boxWidth: 20,
          padding: 10,
          font: {
            size: 12,
          },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        title: {
          display: true,
          text: graphDataY || "Y-axis",
        },

        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        title: {
          display: displayLegend !== false,
          text: graphDataX || "X-axis",
        },

        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
  // fetch data points for impact
  let dpcount;
  const { data: dpData, isSuccess } = useGetImpactDataPointsByImpactIdQuery(
    impactId,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (!isSuccess || !dpData.data) {
    dpcount = 0;
    if (flag) {
      return (
        <>
          <div
            className='card-body d-flex justify-content-center align-items-center'
            style={{
              minHeight: "30px",
              marginBottom: "40px",
              marginTop: "-30px",
            }}
          >
            <span style={{ fontSize: "14px", color: "#555" }}>
              No Data points have been created yet. Please add data points to
              get started.
            </span>
          </div>{" "}
        </>
      );
    }
  } else {
    const sortedData = [...dpData.data].sort((a, b) => a.id - b.id);
    parseDataPoints(sortedData);
    dpcount = sortedData.length;
  }

  return (
    <div>
      {dpcount === 0 ? (
        <>
          <div> *Add data to your impact to enable Health Check</div>
          <Button
            variant='primary'
            className='btn-sm me-2'
            onClick={handleShow}
          >
            <i className='bi bi-plus-circle-fill'></i>
            <span className='ms-2'>Add Data</span>
          </Button>
          <AddEditModal
            impactId={impactId}
            show={showModal}
            handleClose={handleClose}
          />
        </>
      ) : (
        <Bar data={chartData} options={options} />
      )}
    </div>
  );
};

export default MultiTypeChart;

import { NewsItem } from "features/news";
import { useGetNewsFeedByAccountQuery } from "features/news/newsApiSlice";

function NewsByClient({ accountId }) {
  let feedLists = [];
  const feedCount = 5;

  const { data, isSuccess, isError } = useGetNewsFeedByAccountQuery(accountId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    feedLists = data.feedlist;
  }
  if (isError) {
    return null;
  }

  return (
    <>
      <div className='list-group list-group-flush'>
        {feedLists && feedLists.length > 0 ? (
          feedLists.map((feedList, i) => {
            if (i < feedCount) {
              return (
                <NewsItem
                  key={i}
                  title={feedList.feedtitle}
                  comment={feedList.comment}
                  date={feedList.created_on_date}
                />
              );
            } else return null;
          })
        ) : (
          <p className='text-black'>News Feed does not exist for the client</p>
        )}
      </div>
    </>
  );
}

export default NewsByClient;

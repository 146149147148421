import { useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import logo from "../default-thumbnail.png";
import { useGetClientDetailsQuery } from "features/clients/clientSlice";
import { convertDate } from "common/utils";
import { useGetVendorGoalsQuery } from "features/goals/goalsSlice";
import Timeline from "common/decision-timeline/Timeline";
import ImpactsByGoal from "features/impacts/ImpactsByGoal";
import { InitiativeProgressBar } from "features/initiatives";
import {FocusScoreClient} from "features/focus-score";
import FeedBackRep from "features/feed-back/feedback";
function ClientBrief() {
  const location = useLocation();
  const { clientId } = (location && location.state) || {};
  let clientInfo = [];
  const [monthsRemaining, setMonthsRemaining] = useState(0);
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [totalSpend, setTotalSpend] = useState(0);
  const [totalAllocatedBudget, setTotalAllocatedBudget] = useState(0);

  const { data: clientDetailData, isSuccess: clientDetailSuccess } =
    useGetClientDetailsQuery(clientId, {
      refetchOnMountOrArgChange: true,
    });

  if (clientDetailSuccess) {
    clientInfo = clientDetailData.data;
  }
 
  const { data: goalByAccountInfo, isSuccess: isGoalByAccountSuccess } =
    useGetVendorGoalsQuery(clientId, {
      refetchOnMountOrArgChange: true,
    });
  const goalsListByAccount = useMemo(() => {
    if (isGoalByAccountSuccess) {
      return goalByAccountInfo.data;
    }
    return [];
  }, [isGoalByAccountSuccess, goalByAccountInfo]);

  useEffect(() => {
    if (clientDetailSuccess && clientInfo.end_date) {
      const calculateRemainingMonths = () => {
        const endDate = new Date(clientInfo.end_date);
        const currentDate = new Date();

        const yearsDiff = endDate.getFullYear() - currentDate.getFullYear();
        const monthsDiff = endDate.getMonth() - currentDate.getMonth();
        const totalMonthsRemaining = yearsDiff * 12 + monthsDiff;

        if (endDate < currentDate) {
          setMonthsRemaining("End date has passed");
        } else {
          setMonthsRemaining(totalMonthsRemaining);
        }
      };

      setFormattedEndDate(convertDate(clientInfo.end_date));
      calculateRemainingMonths();
    }

    if (isGoalByAccountSuccess) {
      const totalSpendBudget = goalsListByAccount.reduce(
        (acc, goal) => acc + (Number(goal.spend_budget) || 0),
        0,
      );
      const totalAllocated = goalsListByAccount.reduce(
        (acc, goal) => acc + (Number(goal.allocated_budget) || 0),
        0,
      );

      setTotalSpend(totalSpendBudget);
      setTotalAllocatedBudget(totalAllocated);
    }
  }, [
    clientInfo.end_date,
    clientDetailSuccess,
    isGoalByAccountSuccess,
    goalsListByAccount,
  ]);

  let formType= "Client-Brief";

  const renderCrumbs = () => {
    return (
      <>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to={`/`}>Dashboard </Link>
              </li>
              <li className='breadcrumb-item' key={clientId}>
                <Link
                  to={`/clients/${clientId}`}
                  state={{
                    clientId: `${clientId}`,
                  }}
                >
                  Client Detail{" "}
                </Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Client Brief
              </li>
            </ol>
          </nav>
        </div>
      </>
    );
  };

  return (
    <div className='full-container'>
      <div className='row'>
        <div className='col-12'>{renderCrumbs()}</div>
      </div>
      <div className='row me-2'>
        <div className='col'>
          <div className='d-flex align-items-center pb-3'>
            <div className='flex-shrink-0'>
              <img
                src={clientInfo?.image ?? logo}
                alt=''
                className='company-profile me-3'
                onError={(e) => (e.target.src = logo)}
              />
            </div>
            <div className='flex-grow-1'>
              <span>{clientInfo.name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row w-100 py-1 card-conatiner'>
        <div className='col-12 col-md-6 col-lg-3 shadow d-flex p-0 m-2'>
          <div className='card client-brief-card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12 d-flex flex-column align-items-center'>
                  <i className='bi bi-clock-history client-brief-clock'></i>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 d-flex flex-column align-items-center'>
                  <span
                    className={
                      monthsRemaining === "End date has passed"
                        ? ""
                        : "time-rem-month"
                    }
                  >
                    {monthsRemaining}
                  </span>{" "}
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-12 d-flex flex-column align-items-center'>
                  {monthsRemaining !== "End date has passed" ? (
                    <span className='time-rem-month-message'>
                      Months Remaining
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <span className='end-date'>End Date:{formattedEndDate} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3 shadow d-flex p-0 m-2'>
          <div className='card client-brief-card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12 d-flex flex-column align-items-center'>
                  <i className='bi bi-cash-stack client-brief-clock'></i>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 d-flex flex-column align-items-center text-center'>
                  {totalAllocatedBudget === 0 && totalSpend === 0 && (
                    <span className='time-rem-month-message'>
                      No budget information available. Please add allocated and
                      spend budget values.
                    </span>
                  )}

                  {totalAllocatedBudget > 0 && totalSpend === 0 && (
                    <>
                      <span className='budget-message-available'>
                        Allocated Budget: ${totalAllocatedBudget}
                      </span>
                      <span className='time-rem-month-message'>
                        Spend budget information is missing. Please add spend
                        budget values.
                      </span>
                    </>
                  )}

                  {totalSpend > 0 && totalAllocatedBudget === 0 && (
                    <>
                      <span className='budget-message-available'>
                        Spend Budget: ${totalSpend}
                      </span>
                      <span className='time-rem-month-message'>
                        Allocated budget information is missing. Please add
                        allocated budget values.
                      </span>
                    </>
                  )}

                  {totalAllocatedBudget > 0 && totalSpend > 0 && (
                    <>
                      <span className='time-rem-month'>${totalSpend}</span>

                      <span className='budget-message'>
                        Total Spend to Date of{" "}
                        <span className='budget-total'>
                          ${totalAllocatedBudget} Budget
                        </span>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Focus Statement */}
        <div className='col shadow d-flex p-0 m-2 focus-statement-card'>
          <div className='card client-brief-card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12 d-flex flex-column align-items-start statement'>
                  Focus Statement:
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 d-flex flex-column align-items-start'>
                  <span className='contract-statement'>
                    {clientInfo.contract_statement
                      ? clientInfo.contract_statement.length > 200
                        ? `${clientInfo.contract_statement.slice(0, 200)}...`
                        : clientInfo.contract_statement
                      : "No contract purpose provided"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Focus Score */}
        <div className='focus-score-card flex-column align-items-center justify-content-center shadow'>
          <span className='focus-score-title'>
            <strong>FOCUS SCORE</strong>
          </span>
          <div
            data-toggle='tooltip'
            data-placement='top'
            title='Currently, this score is calculated based on the impact scores. Mature version of the score coming soon.'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='none'
              viewBox='0 0 24 24'
              stroke='#fff'
            >
              <circle cx='12' cy='12' r='10' stroke='#fff' stroke-width='2' />
              <line
                x1='12'
                y1='16'
                x2='12'
                y2='12'
                stroke='#fff'
                stroke-width='2'
                stroke-linecap='round'
              />
              <circle cx='12' cy='8' r='1' fill='#fff' />
            </svg>
          </div>
          <div>
              <FocusScoreClient clientId={clientId} formType={formType}/>
          </div>
        </div>
      </div>
      {goalsListByAccount.map((goal, i) => (
        <>
          <div className='row mt-3'>
            <div className='col-12 flex-column align-items-start goal-title mt-2'>
              <div key={goal.id} className='col-12 mb-4'>
                <div className='client-goal-for-display'>
                  <i class='bi bi-caret-right-fill caret-icon'></i>
                  Goal {i + 1}: {goal.title}
                </div>{" "}
              </div>
            </div>
          </div>
          <div className='row w-100 py-1 card-container'>
            <div className='col d-flex p-0 m-2'>
              <Timeline
                goalId={goal.id}
                startDate={clientInfo.start_date}
                endDate={clientInfo.end_date}
              />
            </div>
          </div>
          <div className='row w-100 py-1 card-container'>
            <div className='col shadow d-flex p-0 m-2 client-init-card'>
              <div className='card init-card-prop'>
                <InitiativeProgressBar goalId={goal.id} />
              </div>
            </div>
            <div className='col shadow d-flex p-0 m-2 impacts-by-goal-card'>
              <ImpactsByGoal goalId={goal.id} />
            </div>

            <div className='col shadow d-flex p-0 m-2 feedback-rep-card'>
              <div className='card client-init-card'>
                <FeedBackRep goalId={goal.id} clientId={clientId} />
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default ClientBrief;

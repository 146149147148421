import { formatPhoneNumber } from "common/helpers/formatPhoneNumber";

function ContactsByClient({ client }) {
  return (
    <>
      {client?.primary_contact_email ||
      client?.primary_contact_phoneNumber ||
      client?.secondary_contact ||
      client?.secondary_contact ? (
        <>
          <div className='card-body'>
            <p>Primary Contact</p>
            <div className='type-title'>{client?.primary_contact}</div>
            <div className='inventory-phone text-muted small'>
              <strong>Ph: </strong>
              {formatPhoneNumber(client?.primary_contact_phoneNumber)}
            </div>
            <div className='inventory-email text-muted small'>
              <strong>Email: </strong>
              <span className='email-text'>
                {client?.primary_contact_email}
              </span>
            </div>
          </div>

          {client?.secondary_contact_email ||
          client?.secondary_contact ||
          client?.secondary_contact_phoneNumber ? (
            <div className='card-body'>
              <p>Secondary Contact</p>
              <div className='type-title'>{client?.secondary_contact}</div>
              {client?.secondary_contact_phoneNumber ? (
                <div className='inventory-phone text-muted small'>
                  <strong>Ph: </strong>
                  {formatPhoneNumber(client?.secondary_contact_phoneNumber)}
                </div>
              ) : null}
              {client?.secondary_contact_email ? (
                <div className='inventory-email text-muted small'>
                <strong>Email: </strong>
                <span className='email-text'>{client?.secondary_contact_email}</span>
              </div>
              
              ) : null}
            </div>
          ) : null}
        </>
      ) : (
        <p className='text-black'>Contact Information does not exist.</p>
      )}
    </>
  );
}

export default ContactsByClient;

import { GoalsSnapshot } from "features/goals";
import { ImpactsSnapshot } from "features/impacts";
import { InitiativesSnapshot } from "features/initiatives";
import { Widget } from "common";
import { NewsList } from "features/news/index";
import { Login } from "@microsoft/mgt-react";
import { ScheduleList } from "features/schedule";
import { Task } from "common";
import { ClientList } from "features/clients";
//import FocusScoreChart from "common/charts/FocusScoreChart";
import { FocusScoreCalculation } from "features/focus-score";
import { store } from "app/store";
import {
  useGetMyTasksQuery,
  useUpdateTaskMutation,
} from "features/tasks/tasksSlice";

function Dashboard() {
  let tasks = [];
  let userId;
  const taskForConsultant = true;
  const numIncompleteTask = 5;

  const [updateTask] = useUpdateTaskMutation();

  if (store.getState().auth.userData === null) userId = 0;
  else userId = store.getState().auth.userData.id;

  const { data, isSuccess } = useGetMyTasksQuery(userId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    tasks = data.tasklist;
  }
  let formType = "Dashboard";

  return (
    <>
      <div className='container g-4'>
        <div className='row py-1'>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <GoalsSnapshot />
          </div>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <InitiativesSnapshot />
          </div>
          <div className='col shadow d-flex p-0 m-2 rounded'>
            <ImpactsSnapshot />
          </div>
          {/* <div className='col shadow d-flex p-0 m-2 rounded'>
            <FocusScore userid={userId} formType={formType} />
          </div> */}
          {/* //Focus Score is here */}
          {/* Focus Score */}
          {/* <div className='col shadow d-flex p-0 m-2 rounded'> */}

          <div className='focus-score-card  align-items-center justify-content-center'>
            <span className='focus-score-title'>
              <strong>FOCUS SCORE</strong>
            </span>
            <div
              data-toggle='tooltip'
              data-placement='top'
              title='Currently, this score is calculated based on the impact scores. Mature version of the score coming soon.'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='none'
                viewBox='0 0 24 24'
                stroke='#fff'
              >
                <circle cx='12' cy='12' r='10' stroke='#fff' stroke-width='2' />
                <line
                  x1='12'
                  y1='16'
                  x2='12'
                  y2='12'
                  stroke='#fff'
                  stroke-width='2'
                  stroke-linecap='round'
                />
                <circle cx='12' cy='8' r='1' fill='#fff' />
              </svg>
            </div>
            <div className='focus-score-circle d-flex align-items-center justify-content-center'>
              <span className='focus-score-value'>
                <FocusScoreCalculation userid={userId} formType={formType} />
              </span>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className='row py-1'>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='News Feed'
                dropDownItems={["Filter", "Newsboard", "Style", "Reset"]}
              />
              <div className='card-body'>
                <NewsList />
              </div>
            </div>
          </div>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='Schedule'
                dropDownItems={[<Login />]}
              />
              <div className='card-body'>
                <ScheduleList />
              </div>
            </div>
          </div>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget h-100'>
              <Widget
                name='Tasks'
                dropDownItems={["Filter", "Tasks", "Style", "Reset"]}
              />
              <div className='card-body'>
                <Task
                  tasks={tasks}
                  completedSection={false}
                  updateTask={updateTask}
                  numIncompleteTask={numIncompleteTask}
                  breadcrumb='dashboard'
                  taskForConsultant={taskForConsultant}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row py-1'>
          <div className='col shadow p-0 m-2 rounded'>
            <div className='card dashboard-widget'>
              <Widget
                name='Clients'
                dropDownItems={["Filter", "Clientboard", "Style", "Reset"]}
              />
              <div className='card-body'>
                <ClientList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

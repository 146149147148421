import {
  useGetImpactDataPointsByImpactIdQuery,
  useGetImpactQuery,
  useGetGraphLabelQuery,
} from "features/impacts/impactsSlice";
import { Chart } from "features/impacts";
import { skipToken } from "@reduxjs/toolkit/query";
import { getMonthShorthand } from "common/utils/GetMontShorthand";

function BarGraph({ impactId }) {
  let impactData = [];
  let actualValue = [];
  let targetValue = [];
  let labels = [];
  let impactDataPointsData = [];
  let graphDataX;
  let graphDataY;

  const { data: impactDetails, isSuccess: isImpactDetailsSuccess } =
    useGetImpactQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isImpactDetailsSuccess && impactDetails.data) {
    impactData = impactDetails.data;
  }

  const { data, isSuccess } = useGetImpactDataPointsByImpactIdQuery(
    impactId ? impactId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: graphLabelData, isSuccess: isGraphLabelDataSuccess } =
    useGetGraphLabelQuery(impactId, {
      refetchOnMountOrArgChange: true,
    });

  if (isGraphLabelDataSuccess && Array.isArray(graphLabelData.data)) {
    const relevantGraph = graphLabelData.data.find(
      (entry) => entry.graph_name === "Health Check",
    );
    if (relevantGraph) {
      graphDataX = relevantGraph.label_x_axis;
      graphDataY = relevantGraph.label_y_axis;
    }
  }

  const trackingFrequencyLabel = impactData?.tracking_frequency || "";

  const generateDataSet = () => {
    actualValue = [];
    targetValue = [];
    labels = [];

    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const quarterOrder = ["Q1", "Q2", "Q3", "Q4"];

    const sortDataPoints = (dataPoints) => {
      return dataPoints.sort((a, b) => {
        if (a.year !== b.year) {
          return a.year - b.year;
        }

        if (!isNaN(a.title) && !isNaN(b.title)) {
          return parseInt(a.title) - parseInt(b.title);
        }
        if (monthOrder.includes(a.title) && monthOrder.includes(b.title)) {
          return monthOrder.indexOf(a.title) - monthOrder.indexOf(b.title);
        }

        if (quarterOrder.includes(a.title) && quarterOrder.includes(b.title)) {
          return quarterOrder.indexOf(a.title) - quarterOrder.indexOf(b.title);
        }

        return 0;
      });
    };

    const sortedData = sortDataPoints([...impactDataPointsData]);
    const lastThreeDataPoints = sortedData.slice(-3);

    lastThreeDataPoints.forEach((dataPoint, index) => {
      actualValue[index] = parseFloat(dataPoint.actual_value);
      targetValue[index] = parseFloat(dataPoint.target_value);

      if (
        trackingFrequencyLabel === "Quarterly"
      ) {
        labels[index] = `${dataPoint.title} ${dataPoint.year}`;
      } else if(trackingFrequencyLabel === "Monthly") {
        labels[index] = `${getMonthShorthand(dataPoint.title)} ${dataPoint.year}`;
      } else {
        labels[index] = ` ${dataPoint.title}`;
      }

    });

    return [actualValue, targetValue, labels];
  };

  if (isSuccess) {
    impactDataPointsData = [...data.data].sort((a, b) => a.id - b.id);
    if (impactDataPointsData.length > 0) {
      generateDataSet();
    }
  } else {
    console.log("Data fetch was unsuccessful or skipped.");
  }

  let title = impactData.title;

  return (
    <>
      <div className='container g-4'>
        <div className='row pb-2'>
          {impactDataPointsData.length > 0 ? (
            <Chart
              key={impactId}
              labels={labels}
              data1={actualValue}
              data2={targetValue}
              title={title}
              graphDataX={graphDataX}
              graphDataY={graphDataY}
              trackingFrequencyLabel={trackingFrequencyLabel}
            />
          ) : (
            <div>
              Please add data points to view the graph for impact{" "}
              <strong>{title}</strong>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BarGraph;

import { format } from "date-fns";

const urlRegex =
  /^(https?:\/\/|ftp:\/\/)(www\.)?(([A-Za-z\d-]+\.)+[A-Za-z]{2,}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[[A-Fa-f\d:]+\])(:\d+)?(\/\S*)?$/i;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex = /^\+?1?\d{8,15}$/;
const zipcodeRegex = /^\d{5}$/;
const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

const validateDates = (formValues) => {
  let newErrors = {};

  if (
    formValues.form_start_date === "" ||
    formValues.form_start_date === null
  ) {
    newErrors.form_start_date = "Start date is required";
  }

  if (
    Date.parse(formValues.form_start_date) >
    Date.parse(formValues.form_end_date)
  ) {
    newErrors.form_end_date = "End Date Should be greater than Start date!";
  }

  if (formValues.form_end_date === "" || formValues.form_end_date === null) {
    newErrors.form_end_date = "End date is required";
  }

  if (formValues.form_start_date) {
    const formatedDate = formValues.form_start_date
      ? format(formValues.form_start_date, "yyyy-MM-dd")
      : "";
    if (!dateRegex.test(formatedDate)) {
      newErrors.form_start_date = "Start date format is incorrect";
    }
  }
  if (formValues.form_end_date) {
    const formatedDate = formValues.form_end_date
      ? format(formValues.form_end_date, "yyyy-MM-dd")
      : "";
    if (!dateRegex.test(formatedDate)) {
      newErrors.form_end_date = "End date format is incorrect";
    } else if (
      Date.parse(formValues.form_start_date) >
      Date.parse(formValues.form_end_date)
    ) {
      newErrors.form_end_date = "End Date Should be greater than Start date!";
    }
  }

  return newErrors;
};

export function formValidation(
  formValues,
  goalFormValues,
  impactFormValues,
  labelValues,
  clientFormValues,
  type,
) {
  const commonErrorCheck = (formValues, type) => {
    let newErrors = {};

    if (["Goal", "Initiative", "Task", "Projects"].includes(type)) {
      newErrors = { ...newErrors, ...validateDates(formValues) };
    }

    if (type !== "Client" && type !== "Impact") {
      if (formValues.form_title === "" || formValues.form_title === null) {
        newErrors.form_title = "Title is required";
      }
      if (formValues && formValues.form_title) {
        if (formValues.form_title.length > 200) {
          newErrors.form_title = "Title should not be more than 200 characters";
        }
      }
      if (formValues && formValues.form_description) {
        if (formValues.form_description.length > 500) {
          newErrors.form_description =
            "Description should not be more than 500 characters";
        }
      }
      if (formValues.form_stage === "" || formValues.form_stage === null) {
        newErrors.form_stage = "Stage should not be empty!";
      }
      if (formValues.form_status === "" || formValues.form_status === null) {
        newErrors.form_status = "Status should not be empty!";
      }
      if (
        formValues.form_owner_consultant === "" ||
        formValues.form_owner_consultant === null
      ) {
        newErrors.form_owner_consultant =
          "Owner consultant should not be empty!";
      }

      if (
        formValues.form_client_account === "" ||
        formValues.form_client_account === null
      ) {
        newErrors.form_client_account = "Client Account should not be empty!";
      }
      if (type === "Initiative" && goalFormValues.form_spend_budget < 0) {
        newErrors.form_spend_budget = "Budget value should not be negative";
      }
      if (
        type === "Projects" &&
        (formValues.form_initiative === "" ||
          formValues.form_initiative === null)
      ) {
        newErrors.form_initiative = "Initiative should not be empty";
      }
      if (
        (type !== "Goal" && formValues.form_goal === "") ||
        formValues.form_goal === null
      ) {
        newErrors.form_goal = "Goal should not be empty!";
      }
      return newErrors;
    } else {
      return null;
    }
  };
  const goalErrorCheck = (goalFormValues, type) => {
    let newErrors = {};
    if (type === "Goal") {
      if (goalFormValues.form_allocated_budget >= 9999999999) {
        newErrors.form_allocated_budget = "Allocated budget is too big";
      }
      if (goalFormValues.form_allocated_budget < 0) {
        newErrors.form_allocated_budget = "Budget value should not be negative";
      }
      if (goalFormValues.form_spend_budget >= 9999999999) {
        newErrors.form_spend_budget = "Spend budget is too big";
      }

      if (goalFormValues.form_spend_budget >= 999999) {
        newErrors.form_spend_budget = "Spend budget is too Big";
      }

      return newErrors;
    } else {
      return null;
    }
  };
  const impactErrorCheck = (impactFormValues, labelValues, type) => {
    let newErrors = {};

    if (["Goal", "Initiative", "Impact", "Task", "Project"].includes(type)) {
      newErrors = { ...newErrors, ...validateDates(impactFormValues) };
    }

    if (type === "Impact") {
      if (
        labelValues.form_label_x_axis &&
        labelValues.form_label_x_axis.length > 200
      ) {
        newErrors.form_label_x_axis = "Label for X axis exceeds max length";
      }
      if (
        labelValues.form_label_y_axis === "" ||
        labelValues.form_label_y_axis == null
      ) {
        newErrors.form_label_y_axis = "Label for Y axis is required";
      }
      if (labelValues && labelValues.form_label_y_axis) {
        if (labelValues.form_label_y_axis.length > 200) {
          newErrors.form_label_y_axis = "Label for Y axis exceeds max length";
        }
      }
      if (
        impactFormValues.form_tracking_frequency === "" ||
        impactFormValues.form_tracking_frequency == null
      ) {
        newErrors.form_tracking_frequency = "Tracking frequency is required";
      }
      if (impactFormValues.form_overall_target_value > 999999999999) {
        newErrors.form_overall_target_value = "Overall Target Value is too big";
      }
      if (!impactFormValues.form_overall_target_value) {
        newErrors.form_overall_target_value =
          "Overall Target Value is required";
      }
      if (impactFormValues.form_overall_target_value < 0) {
        newErrors.form_overall_target_value =
          "Overall Target Value cannot be negative";
      }
      if (impactFormValues.form_impact_type === "Goal") {
        if (
          impactFormValues.form_goal === "" ||
          impactFormValues.form_goal == null
        ) {
          newErrors.form_goal = "Goal is required";
        }
      }
      if (impactFormValues.form_impact_type === "Initiative") {
        if (
          impactFormValues.form_initiative === "" ||
          impactFormValues.form_initiative === null
        ) {
          newErrors.form_initiative = "Initiative is required";
        }
      }

      if (
        impactFormValues.form_title === "" ||
        impactFormValues.form_title === null
      ) {
        newErrors.form_title = "Title is required";
      }
      if (impactFormValues && impactFormValues.form_title) {
        if (impactFormValues.form_title.length > 200) {
          newErrors.form_title = "Title should not be more than 200 characters";
        }
      }
      if (impactFormValues && impactFormValues.form_description) {
        if (impactFormValues.form_description.length > 500) {
          newErrors.form_description =
            "Description should not be more than 500 characters";
        }
      }
      if (
        impactFormValues.form_owner_consultant === "" ||
        impactFormValues.form_owner_consultant === null
      ) {
        newErrors.form_owner_consultant =
          "Owner consultant should not be empty!";
      }
      if (
        impactFormValues.form_client_account === "" ||
        impactFormValues.form_client_account === null
      ) {
        newErrors.form_client_account = "Client Account should not be empty!";
      }
      if (
        impactFormValues.form_impact_type === "Goal" &&
        impactFormValues.form_goal === null
      ) {
        newErrors.form_goal = "Goal should not be empty!";
      }
      if (
        impactFormValues.form_impact_type === "Initiative" &&
        impactFormValues.form_initiative === null
      ) {
        newErrors.form_initiative = "Initiative should not be empty!";
      }
      return newErrors;
    } else {
      return null;
    }
  };

  const clientErrorCheck = (clientFormValues, type) => {
    let newErrors = {};
    if (type === "Client") {
      if (
        clientFormValues.form_name === "" ||
        clientFormValues.form_name === null
      ) {
        newErrors.form_name = "Client name is required";
      }
      if (
        clientFormValues.form_primary_consultant === "" ||
        clientFormValues.form_primary_consultant === null
      ) {
        newErrors.form_primary_consultant = "Primary consultant is required";
      }
      if (
        clientFormValues.form_start_date === "" ||
        clientFormValues.form_start_date === null
      ) {
        newErrors.form_start_date = "Start date is required";
      }
      if (clientFormValues.form_start_date) {
        const startDate = clientFormValues?.form_start_date;
        const formatedStartDate = startDate
          ? format(startDate, "yyyy-MM-dd")
          : "";

        if (!dateRegex.test(formatedStartDate)) {
          newErrors.form_start_date = "Start date format is incorrect";
        }
      }
      if (clientFormValues.form_end_date) {
        const endDate = clientFormValues?.form_end_date;
        const formatedendDate = endDate ? format(endDate, "yyyy-MM-dd") : "";
        if (!dateRegex.test(formatedendDate)) {
          newErrors.form_end_date = "End date format is incorrect";
        }
      }
      if (
        Date.parse(clientFormValues.form_start_date) >
        Date.parse(clientFormValues.form_end_date)
      ) {
        newErrors.form_end_date = "End Date Should be greater than Start date!";
      }

      if (
        clientFormValues.form_end_date === "" ||
        clientFormValues.form_end_date === null
      ) {
        newErrors.form_end_date = "End date is required";
      }

      if (clientFormValues && clientFormValues.form_name) {
        if (clientFormValues.form_name.length > 200) {
          newErrors.form_name = "Title is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_website) {
        if (clientFormValues.form_website.length > 200) {
          newErrors.form_website = "Website is too long";
        }
      }
      if (
        clientFormValues.form_account_type === "" ||
        clientFormValues.form_account_type === null
      ) {
        newErrors.form_account_type = "Account Type is required";
      }
      if (
        clientFormValues.form_address === "" ||
        clientFormValues.form_address === null
      ) {
        newErrors.form_address = "Address is required";
      }
      if (
        clientFormValues.form_city === "" ||
        clientFormValues.form_city === null
      ) {
        newErrors.form_city = "City is required";
      }
      if (
        clientFormValues.form_state === "" ||
        clientFormValues.form_state === null
      ) {
        newErrors.form_state = "State is required";
      }
      if (
        clientFormValues.form_zipcode === "" ||
        clientFormValues.form_zipcode === null
      ) {
        newErrors.form_zipcode = "Zip code is required";
      }

      if (
        clientFormValues.form_website !== null &&
        clientFormValues.form_website !== "" &&
        !urlRegex.test(clientFormValues.form_website)
      ) {
        newErrors.form_website = "Website is in incorrect format";
      }

      if (
        clientFormValues.form_primary_contact_email !== null &&
        !emailRegex.test(clientFormValues.form_primary_contact_email)
      ) {
        newErrors.form_primary_contact_email = "Email is in incorrect format";
      }
      if (
        clientFormValues.form_primary_contact_title === "" ||
        clientFormValues.form_primary_contact_title === null
      ) {
        newErrors.form_primary_contact_title = "Title is required";
      }
      if (
        clientFormValues.form_primary_contact_email === "" ||
        clientFormValues.form_primary_contact_email === null
      ) {
        newErrors.form_primary_contact_email = "Email is required";
      }
      if (
        clientFormValues.form_primary_contact === "" ||
        clientFormValues.form_primary_contact === null
      ) {
        newErrors.form_primary_contact = "Primary Contact is required";
      }
      if (
        clientFormValues.form_country === null ||
        clientFormValues.form_country === ""
      ) {
        newErrors.form_country = "Country name is required";
      }

      if (clientFormValues && clientFormValues.form_contract_statement) {
        if (clientFormValues.form_contract_statement.length > 250) {
          newErrors.form_contract_statement =
            "The contract purpose is too long. It exceeds 250 characters.";
        }
      }
      if (clientFormValues && clientFormValues.form_primary_contact_title) {
        if (clientFormValues.form_primary_contact_title.length > 200) {
          newErrors.form_city = "Title is too long";
        }
      }

      if (
        clientFormValues &&
        clientFormValues.form_primary_contact_phoneNumber
      ) {
        const phoneNumbre =
          clientFormValues.form_primary_contact_phoneNumber.replace(/\D/g, "");

        if (phoneNumbre.length > 10) {
          newErrors.form_primary_contact_phoneNumber =
            "Phone number can not exceed 10 digits";
        } else if (phoneNumbre.length < 10) {
          newErrors.form_primary_contact_phoneNumber =
            "Phone number must be exactly 10 digits";
        } else if (!phoneRegex.test(phoneNumbre)) {
          newErrors.form_primary_contact_phoneNumber =
            "Phone number is in incorrect format";
        }
      } else {
        newErrors.form_primary_contact_phoneNumber =
          "Primary contact phone number is required.";
      }
      if (
        clientFormValues &&
        clientFormValues.form_secondary_contact_phoneNumber
      ) {
        const phoneNumbre =
          clientFormValues.form_secondary_contact_phoneNumber.replace(
            /\D/g,
            "",
          );

        if (phoneNumbre.length > 10) {
          newErrors.form_secondary_contact_phoneNumber =
            "Phone number can not exceed 10 digits";
        } else if (phoneNumbre.length < 10) {
          newErrors.form_secondary_contact_phoneNumber =
            "Phone number must be exactly 10 digits";
        } else if (!phoneRegex.test(phoneNumbre)) {
          newErrors.form_secondary_contact_phoneNumber =
            "Phone number is in incorrect format";
        }
      }
      if (clientFormValues && clientFormValues.form_city) {
        if (clientFormValues.form_city.length > 200) {
          newErrors.form_city = "City name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_state) {
        if (clientFormValues.form_state.length > 200) {
          newErrors.form_state = "State name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_country) {
        if (
          clientFormValues.form_country !== null &&
          clientFormValues.form_country.length > 200
        ) {
          newErrors.form_country = "Country name is too long";
        }
      }
      if (clientFormValues && clientFormValues.form_zipcode) {
        if (
          !zipcodeRegex.test(clientFormValues.form_zipcode) ||
          clientFormValues.form_zipcode.length > 5
        ) {
          newErrors.form_zipcode = "Zip code is in incorrect format";
        }
      }
      if (
        Date.parse(clientFormValues.form_start_date) >
        Date.parse(clientFormValues.form_end_date)
      ) {
        newErrors.form_end_date =
          "The end date must be later than the start date";
      }
      return newErrors;
    } else {
      return null;
    }
  };

  const commonErrors = commonErrorCheck(formValues, type);
  const goalErrors = goalErrorCheck(goalFormValues, type);
  const impactErrors = impactErrorCheck(impactFormValues, labelValues, type);
  const clientErrors = clientErrorCheck(clientFormValues, type);

  return { ...commonErrors, ...goalErrors, ...impactErrors, ...clientErrors };
}

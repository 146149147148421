import {
  Modal,
  Form,
  Accordion,
  Button,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { useGetGoalImpactsQuery } from "features/impacts/impactsSlice";
import { useAddDecisionPointMutation } from "./decisionPointSlice";
import { useState } from "react";
import { CancelDialog } from "common";
import moment from "moment";
import { ValidationDecisionPoint } from "features/decision-point";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";

const AddDecisionPoint = ({ show, handleClose, goalId, goalTitle }) => {
  let impactListByGoal = [];
  let impactListLength;
  const [cancelModel, setCancelModel] = useState(false);
  const CANCEL_DIALOG_TITLE = "Decision Point";
  const CANCEL_DIALOG_MESSAGE = `Are you sure you want to cancel? Any unsaved changes will be lost.`;
  const { data, isSuccess } = useGetGoalImpactsQuery(goalId, {
    refetchOnMountOrArgChange: true,
  });
  const inputInitialValues = {
    goal: goalId,
    milestone_date: "",
    title: "",
    summary: "",
  };
  const [inputData, setInputData] = useState(inputInitialValues);
  const [errors, setErrors] = useState({});

  if (isSuccess && data?.data) {
    impactListLength = data.data.length;
    impactListByGoal = data.data;
  }
  const [addDecisionPoint] = useAddDecisionPointMutation();
  const handleSave = async (e) => {
    e.preventDefault();
    const buttonId = e.target.id;
    const validationErrors = ValidationDecisionPoint(inputData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        await addDecisionPoint(inputData).unwrap();
        // handleSuccess("Add");
        toast.success("Decision Point has been added successfully");
        if (buttonId === "saveButton") {
          handleClose();
        }
        setInputData(inputInitialValues);
        setErrors({});
      } catch (error) {
        toast.error(
          `${error?.message}` || "Decision Point has been added successfully",
        );
        console.error("Failed to save data point:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY");
  };
  const cancelDialog = () => {
    setCancelModel(true);
  };
  const hideCancelDialog = () => {
    setCancelModel(false);
  };
  const cancelHandle = () => {
    setInputData(inputInitialValues);
    setErrors({});
    setCancelModel(false);
    handleClose();
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size='xl'>
        <div className='d-flex justify-content-between align-items-center m-2'>
          <Modal.Title className="ms-1"> Add Decision Point</Modal.Title>
          <Button
            id='closeButton'
            className="me-1 mb-2"
            variant='close'
            onClick={handleClose}
            aria-label='Close'
          ></Button>
        </div>
        <Modal.Body>
          <Form>
            <div className='row mb-3'>
              <div className='col-6'>
                <Form.Group controlId='goalTitle'>
                  <Form.Control
                    value={`Goal Title: ${goalTitle}`}
                    disabled
                    data-bs-toggle='tooltip'
                    title='Goal'
                  />
                </Form.Group>
              </div>
              <div className='col-6 mb-3 input-wrp goal-input-wrap'>
                <div className='w-100 goal-input position-relative'>
                  <DatePicker
                    selected={
                      inputData.milestone_date
                        ? new Date(inputData.milestone_date + "T00:00:00")
                        : null
                    }
                    className={`form-control ${errors?.milestone_date ? "is-invalid" : ""}`}
                    id='formDate'
                    placeholderText='Evaluation Date'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Evaluation Date'
                    onChange={(date) =>
                      setInputData({
                        ...inputData,
                        milestone_date: date
                          ? date.toISOString().split("T")[0]
                          : "",
                      })
                    }
                    required
                    autoComplete='off'
                  />
                  {errors?.milestone_date && (
                    <p className='text-danger'>{errors.milestone_date}</p>
                  )}
                  <div
                    className='calendar-icon position-absolute end-0 pe-3 me-1'
                    onClick={(e) => {
                      e.stopPropagation();
                      document.getElementById("formDate")?.focus();
                    }}
                    style={{
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "10px",
                      backgroundColor: "white",
                      paddingLeft: "5px",
                    }}
                  >
                    {!errors?.milestone_date && (
                      <FaCalendarAlt size={18} color='#6c757d' />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {second row} */}
            <div className='row mb-3'>
              <div className='col-12'>
                <Form.Group controlId=''>
                  <Form.Control
                    type='text'
                    placeholder='Decision Point Title'
                    value={inputData.title}
                    data-bs-toggle='tooltip'
                    title='Title'
                    onChange={(e) =>
                      setInputData({ ...inputData, title: e.target.value })
                    }
                    required
                  />
                  {errors.title && <p className="error-text">{errors.title}</p>}
                </Form.Group>
              </div>
            </div>
            {/* {Third row} */}
            <div className='row mb-3'>
              <div className='col-6'>
                <Form.Group controlId='defineDecisionPoint'>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    value={inputData.summary}
                    placeholder='Define Decision Point'
                    data-bs-toggle='tooltip'
                    title='Define Decision Point'
                    onChange={(e) =>
                      setInputData({ ...inputData, summary: e.target.value })
                    }
                    required
                  />
                  {errors.summary && <p className="error-text">{errors.summary}</p>}
                </Form.Group>
              </div>
              <div className='col-6 text-truncate'>
                <Accordion>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header
                      data-bs-toggle='tooltip'
                      title='Impact List'
                    >
                      Impact List
                    </Accordion.Header>
                    <Accordion.Body>
                      {impactListLength > 0 ? (
                        <ListGroup as='ol' numbered>
                          {impactListByGoal.map((impact) => (
                            <ListGroup.Item
                              as='li'
                              key={impact.id}
                              className='d-flex justify-content-between align-items-start'
                            >
                              <div className='ms-2 me-auto'>
                                <div
                                  className='text-content'
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px",
                                  }}
                                >
                                  {impact.title}
                                </div>
                              </div>

                              {impact.end_date ? (
                                <Badge bg='secondary' pill>
                                  End Date: {formatDate(impact.end_date)}
                                </Badge>
                              ) : (
                                ""
                              )}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        "No Impacts available"
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </Form>
          <div className='d-flex justify-content-end gap-2'>
            <Button
              id='cancelButton'
              variant='outline-secondary'
              className='me-2'
              onClick={() => {
                cancelDialog();
              }}
            >
              Cancel
            </Button>
            <Button id='saveButton' variant='primary' onClick={handleSave}>
              Save
            </Button>
            <Button id='addAnother' variant='primary' onClick={handleSave}>
              Add Another
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {cancelModel ? (
        <div className='cancel-modal'>
          <CancelDialog
            dialogTitle={CANCEL_DIALOG_TITLE}
            descriptionText={CANCEL_DIALOG_MESSAGE}
            handleCancel={() => cancelHandle()}
            hide={() => hideCancelDialog()}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddDecisionPoint;
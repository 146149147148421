import { store } from "app/store";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientDetail } from "features/clients";
import { useGetVendorImpactsQuery } from "features/impacts/impactsSlice";
import { Comment, FormPromptModal } from "common";
import { InitiativeDropDown } from "features/impacts";
import {
  useGetCommentsQuery,
  useAddCommentsMutation,
} from "common/comments/commentSlice";
import {
  useGetClientDetailsQuery,
  useGetClientFeedbackDetailsQuery,
} from "features/clients/clientSlice";
import { GoalByTypeSnapshot } from "features/goals";
import { InitiativeByTypeSnapshot } from "features/initiatives";
import { ProjectByTypeSnapshot } from "features/projects";
import { PROJECTTYPE } from "features/projects/ProjectByTypeSnapshot";

import { GOALTYPE } from "features/goals/GoalByTypeSnapshot";
import { INITIATIVETYPE } from "features/initiatives/InitiativeByTypeSnapshot";
import { TaskByTypeSnapshot } from "features/tasks";
import { TASKTYPE } from "features/tasks/TaskByTypeSnapshot";
import { useState } from "react";
import FeedbackForm from "features/clients/FeedbackForm";

function Client() {
  const location = useLocation();
  const navigate = useNavigate();

  const userId = store.getState().auth.userData.id;
  let impactsByClientInfo = [];
  let clientInfo = [];
  let clientFeedbackInfo = [];
  const { clientId } = (location && location.state) || {};
  let accountComments = [];
  let type = "account";
  let parentId = clientId;
  let feedback_exist = false;
  const [clientPromptShow, setClientPromptShow] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(false);

  const {
    data: clientDetailData,
    isSuccess: clientDetailSuccess,
    isError: clientDetailError,
  } = useGetClientDetailsQuery(clientId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: impactsData, isSuccess: isImpactSuccess } =
    useGetVendorImpactsQuery(clientId, {
      refetchOnMountOrArgChange: true,
    });
  const { data: clientFeedbackData, isSuccess: clientFeedbackSuccess } =
    useGetClientFeedbackDetailsQuery(clientId, {
      refetchOnMountOrArgChange: true,
    });
  if (clientFeedbackSuccess) {
    clientFeedbackInfo = clientFeedbackData.data;
  }

  const { data: accountCommentData, isSuccess: isCommentSuccess } =
    useGetCommentsQuery(
      { type, parentId },
      {
        refetchOnMountOrArgChange: true,
      },
    );

  const [addAccountComments] = useAddCommentsMutation({
    refetchOnMountOrArgChange: true,
  });

  if (clientDetailSuccess) {
    clientInfo = clientDetailData.data;
  }

  if (clientDetailSuccess && !clientDetailData.data) {
    return null;
  }

  if (clientDetailError) {
    return null;
  }

  if (isImpactSuccess) {
    impactsByClientInfo = impactsData.data;
  }

  if (isCommentSuccess && accountCommentData.comments) {
    accountComments = accountCommentData.comments;
  }
  const PROMPT_DIALOG_TITLE = "Client";
  const PROMPT_DIALOG_MESSAGE = `Please fill out the feedback form for "${clientInfo.name}" `;

  const handleCommentSubmit = async (formData) => {
    formData.append("comment_type_id", parentId);
    formData.append("user_id", userId);
    formData.append("type", type);
    await addAccountComments(formData);
  };
  const handleClientBrief = () => {
    clientFeedbackInfo.forEach((item) => {
      if (item.feedback_exists === true) {
        feedback_exist = true;
      }
    });
    if (feedback_exist === true) {
      navigate(`/client-brief/${clientId}`, {
        state: {
          clientId: clientId,
        },
      });
    } else {
      setClientPromptShow(true);
    }
  };
  const confirmHandle = () => {
    setClientPromptShow(false);
    setFeedbackShow(true);
  };
  const hideCancelDialog = () => {
    setClientPromptShow(false);
  };
  console.log(impactsByClientInfo, "clientimo");

  const renderCrumbs = () => {
    return (
      <>
        <div className='row'>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to={`/`}>Dashboard</Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Client Detail
              </li>
            </ol>
          </nav>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='container g-4'>
        <div className='row align-items-center mb-3'>
          <div className='col'>{renderCrumbs()}</div>
          <div className='col text-end'>
            <button
              onClick={() => handleClientBrief()}
              className='btn btn-primary'
            >
              Client Brief
            </button>
          </div>
          {clientPromptShow ? (
            <div>
              <FormPromptModal
                dialogTitle={PROMPT_DIALOG_TITLE}
                descriptionText={PROMPT_DIALOG_MESSAGE}
                handleConfirm={() => confirmHandle()}
                hide={() => hideCancelDialog()}
              />
            </div>
          ) : (
            ""
          )}
          {feedbackShow && (
            <FeedbackForm
              clientId={clientId}
              feedbackShow={feedbackShow}
              onClose={() => setFeedbackShow(false)}
            />
          )}
        </div>
        <div id='client-detail' className='row'>
          <div className='col-4'>
            <ClientDetail clientInfo={clientInfo} clientId={clientId} />
          </div>

          <div className='col-8'>
            <div className='row mb-4'>
              <div className='col-6'>
                <GoalByTypeSnapshot
                  type={GOALTYPE.VENDOR}
                  clientId={clientId}
                />
              </div>
              <div className='col-6'>
                <InitiativeByTypeSnapshot
                  id={clientId}
                  type={INITIATIVETYPE.VENDOR}
                />
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-6'>
                {" "}
                <ProjectByTypeSnapshot
                  type={PROJECTTYPE.VENDOR}
                  goalOrInitOrClientId={clientId}
                />
              </div>
              <div className='col-6'>
                <TaskByTypeSnapshot
                  type={TASKTYPE.VENDOR}
                  goalOrClientId={clientId}
                />
              </div>
            </div>
            <div className='row mb-4'>
              {impactsByClientInfo.length > 0 ? (
                <InitiativeDropDown impactsInfo={impactsByClientInfo} />
              ) : (
                <div className='col'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='text-center text-muted'>
                        <p>
                          No data available. Please add Impact to view the
                          graph.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Comment
              comments={accountComments}
              handleCommentSubmit={handleCommentSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Client;

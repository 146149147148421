function ContactItem() {
  return (
    <>
      <div className='list-group-item lgi-flush'>
        <div className='d-flex align-items-start'>
          {/* <div className='flex-shrink-0 me-3'> */}
          {/*<img src="img/rose.jpg" className="contact" alt="profile image" />*/}
          {/* <span className='contact align-items-center justify-content-center'> */}
          {/* RT */}
          {/* </span> */}
          {/* </div> */}
          <div className='flex-grow-1'>
            {/* <p className='list-contact-name'>Rose Tyler</p>
						<p className='list-contact-phone'>
							ph:
							<span> +1 (321)456-0987</span>
						</p>
						<p className='list-contact-email'>
							email:
							<span className='text-muted'> doc@who.io</span>
						</p> */}
            Contact Information does not exist.
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactItem;

import {
  Modal,
  Form,
  Accordion,
  Button,
  ListGroup,
  Badge,
} from "react-bootstrap";
import {
  useGetDecisionPointQuery,
  useUpdateDecisionPointMutation,
  useDeleteDecisionPointMutation,
} from "./decisionPointSlice";
import { useGetGoalImpactsQuery } from "features/impacts/impactsSlice";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState, useEffect } from "react";
import { DeleteDialog, CancelDialog } from "common";
import { ValidationDecisionPoint } from "features/decision-point";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";

const EditDecisionPoint = ({
  decisionPointId,
  goalId,
  goalTitle,
  show,
  handleClose,
}) => {
  let decisionPointInfo;
  const DELETE_DIALOG_TITLE = "Decision Point";
  const DELETE_DIALOG_MESSAGE = `Are you sure you want to delete this decision point?`;
  const CANCEL_DIALOG_TITLE = "Decision Point";
  const CANCEL_DIALOG_MESSAGE = `Are you sure you want to cancel? Any unsaved changes will be lost.`;
  const [deleteModel, setDeleteModel] = useState(false);
  const [cancelModel, setCancelModel] = useState(false);

  const { data, isSuccess } = useGetDecisionPointQuery(
    decisionPointId ? decisionPointId : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  if (isSuccess) {
    decisionPointInfo = data.data;
  }
  const inputInitialValues = {
    goal: goalId,
    milestone_date: "",
    title: "",
    summary: "",
  };
  const [inputData, setInputData] = useState(inputInitialValues);
  const [errors, setErrors] = useState({});

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY");
  };
  //Fetch Impact list by Goal
  let impactListByGoal = [];
  let impactListLength;
  const { data: ImpactList, isSuccess: isImpactListSuccess } =
    useGetGoalImpactsQuery(goalId, {
      refetchOnMountOrArgChange: true,
    });
  if (isImpactListSuccess && ImpactList?.data) {
    impactListLength = ImpactList.data.length;
    impactListByGoal = ImpactList.data;
  }
  useEffect(() => {
    if (decisionPointInfo) {
      setInputData({
        goalId: goalId,
        milestone_date: decisionPointInfo.milestone_date,
        title: decisionPointInfo.title,
        summary: decisionPointInfo.summary,
      });
    }
  }, [decisionPointInfo, goalId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [updateDecisionPoint] = useUpdateDecisionPointMutation();
  const handleSave = async (e) => {
    e.preventDefault();
    const validationErrors = ValidationDecisionPoint(inputData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const updateDecision = await updateDecisionPoint({
          decisionPointId: decisionPointId,
          body: inputData,
        });

        if (updateDecision?.error?.message?.status !== "error") {
          toast.success("Decision Point has been updated successfully.");
        } else {
          toast.error("Failed to update desicion point.");
        }
        setErrors({});
        handleClose();
      } catch (error) {
        toast.error(error?.message || "Failed to update desicion point.");
        console.error("Failed to save data point:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };
  const [deleteDecisionPoint] = useDeleteDecisionPointMutation();
  const deleteHandle = async () => {
    if (decisionPointId) {
      await deleteDecisionPoint(decisionPointId);
    }
    handleClose();
  };
  const deleteDialog = () => {
    setDeleteModel(true);
  };
  const hideDialog = () => {
    setDeleteModel(false);
  };
  const cancelDialog = () => {
    setCancelModel(true);
  };
  const hideCancelDialog = () => {
    setCancelModel(false);
  };
  const cancelHandle = () => {
    setInputData(inputInitialValues);
    setErrors({});
    setCancelModel(false);

    handleClose();
  };

  return (
    <div>
      {" "}
      <div>
        <Modal show={show} onHide={handleClose} size='xl'>
          <div className='d-flex m-2'>
            <Modal.Title className='ms-1'>Edit Decision Point</Modal.Title>
          </div>

          <Modal.Body>
            <Form>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Form.Group controlId='goalTitle'>
                    <Form.Control
                      value={`Goal Title: ${goalTitle}`}
                      disabled
                      data-bs-toggle='tooltip'
                      title='Goal'
                    />
                  </Form.Group>
                </div>
                <div className='col-6 mb-3 input-wrp goal-input-wrap'>
                  <div className='w-100 goal-input position-relative'>
                    <DatePicker
                      selected={
                        inputData.milestone_date
                          ? new Date(inputData.milestone_date + "T00:00:00")
                          : null
                      }
                      className={`form-control ${errors?.milestone_date ? "is-invalid" : ""}`}
                      id='formDate'
                      placeholderText='Evaluation Date'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Evaluation Date'
                      onChange={(date) => {
                        if (!date) return;

                        const formattedDate = date.toISOString().split("T")[0];

                        const event = {
                          target: {
                            name: "milestone_date",
                            value: formattedDate,
                          },
                        };
                        handleChange(event);
                      }}
                      required
                      autoComplete='off'
                    />

                    {errors?.milestone_date && (
                      <p className='text-danger'>{errors.milestone_date}</p>
                    )}
                    <div
                      className='calendar-icon position-absolute end-0 pe-3 me-1'
                      onClick={(e) => {
                        e.stopPropagation();
                        document.getElementById("formDate")?.focus();
                      }}
                      style={{
                        cursor: "pointer",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: "10px",
                        backgroundColor: "white",
                        paddingLeft: "5px",
                      }}
                    >
                      {!errors?.milestone_date && (
                        <FaCalendarAlt size={18} color='#6c757d' />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {second row} */}
              <div className='row mb-3'>
                <div className='col-12'>
                  <Form.Group controlId=''>
                    <Form.Control
                      type='text'
                      placeholder='Decision Point Title'
                      name='title'
                      value={inputData.title}
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Title'
                      onChange={handleChange}
                      required
                    />
                    {errors.title && <p>{errors.title}</p>}
                  </Form.Group>
                </div>
              </div>
              {/* {Third row} */}
              <div className='row mb-3'>
                <div className='col-6'>
                  <Form.Group controlId='defineDecisionPoint'>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      name='summary'
                      value={inputData.summary}
                      placeholder='Define Decision Point'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      title='Define Decision Point'
                      onChange={handleChange}
                      required
                    />
                    {errors.summary && <p>{errors.summary}</p>}
                  </Form.Group>
                </div>
                <div className='col-6 text-truncate'>
                  <Accordion>
                    <Accordion.Item eventKey='0'>
                      <Accordion.Header
                        data-bs-toggle='tooltip'
                        title='Impact List'
                      >
                        Impact List
                      </Accordion.Header>
                      <Accordion.Body>
                        {impactListLength > 0 ? (
                          <ListGroup as='ol' numbered>
                            {impactListByGoal.map((impact) => (
                              <ListGroup.Item
                                as='li'
                                key={impact.id}
                                className='d-flex justify-content-between align-items-start'
                              >
                                <div className='ms-2 me-auto'>
                                  <div
                                    className='text-content'
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    {impact.title}
                                  </div>
                                </div>
                                {impact.end_date ? (
                                  <Badge bg='secondary' pill>
                                    End Date: {formatDate(impact.end_date)}
                                  </Badge>
                                ) : (
                                  ""
                                )}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        ) : (
                          "No Impacts available"
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Form>
            <div className='d-flex justify-content-between'>
              <Button
                id='deleteButton'
                variant='danger'
                size='lg'
                onClick={() => deleteDialog()}
              >
                Delete
              </Button>
              <div className='ml-2'>
                <Button
                  id='cancelButton'
                  variant='outline-secondary'
                  size='lg'
                  onClick={() => {
                    cancelDialog();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id='saveButton'
                  className='ms-3'
                  variant='primary'
                  size='lg'
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {deleteModel ? (
          <div className='delete-modal'>
            <DeleteDialog
              dialogTitle={DELETE_DIALOG_TITLE}
              descriptionText={DELETE_DIALOG_MESSAGE}
              handleDelete={() => deleteHandle()}
              hide={() => hideDialog()}
            />
          </div>
        ) : null}
        {cancelModel ? (
          <div className='cancel-modal'>
            <CancelDialog
              dialogTitle={CANCEL_DIALOG_TITLE}
              descriptionText={CANCEL_DIALOG_MESSAGE}
              handleCancel={() => cancelHandle()}
              hide={() => hideCancelDialog()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditDecisionPoint;

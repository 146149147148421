import logo from "../../default-thumbnail.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ProjectsByClient } from "features/projects";
import { ContactsByClient } from "features/contacts";
import { NewsByClient } from "features/news";
import { TasksByClient } from "features/tasks";

function ClientInventory({ client }) {
  const [inventoryId, setInventoryId] = useState(1);

  const handleChange = (inventoryId) => {
    setInventoryId(inventoryId);
  };

  return (
    <>
      <div className='col'>
        <div className='card h-100'>
          <div className='card-header company-header py-3'>
            <Link
              to={`/clients/${client?.id}`}
              state={{ clientId: `${client?.id}` }}
            >
              <div className='d-flex align-items-center pb-3'>
                <div className='flex-shrink-0'>
                  <img
                    src={client?.image ?? logo}
                    alt=''
                    className='company-profile me-3'
                    onError={(e) => (e.target.src = logo)}
                  />
                </div>
                <div className='flex-grow-1'>
                  <p className='company-name'>{client?.name}</p>
                  <p className='text-muted small'>{client?.account_type}</p>
                </div>
              </div>
            </Link>
            <div className='d-flex align-items-center justify-content-center'>
              <button className='client-options btn'>
                <i
                  className='bi bi-briefcase'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Projects'
                  data-bs-original-title='Projects'
                  aria-label='Projects'
                  onClick={() => handleChange(1)}
                ></i>
              </button>
              <button className='client-options btn'>
                <i
                  className='bi bi-check2-square'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Tasks'
                  data-bs-original-title='Tasks'
                  aria-label='Tasks'
                  onClick={() => handleChange(2)}
                ></i>
              </button>
              <button className='client-options btn'>
                <i
                  className='bi bi-person'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Contacts'
                  data-bs-original-title='Contacts'
                  aria-label='Contacts'
                  onClick={() => handleChange(3)}
                ></i>
              </button>
              <button className='client-options btn'>
                <i
                  className='bi bi-broadcast'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='News Feed'
                  data-bs-original-title='News Feed'
                  aria-label='News Feed'
                  onClick={() => handleChange(4)}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body pt-3'>
            {inventoryId === 1 ? (
              <ProjectsByClient clientId={client?.id} />
            ) : inventoryId === 2 ? (
              <TasksByClient clientId={client?.id} />
            ) : inventoryId === 3 ? (
              <ContactsByClient client={client} />
            ) : (
              <NewsByClient accountId={client?.id} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientInventory;

import { ProjectItem } from "features/projects";
import { useGetConsultantProjectsQuery } from "features/projects/projectsSlice";
import { store } from "app/store";

function ProjectsList() {
	let projectsLists = [];
	let consultantId;
	if (store.getState().auth.userData)
		consultantId = store.getState().auth.userData.id;

	const { data, isSuccess } = useGetConsultantProjectsQuery(consultantId, {
		refetchOnMountOrArgChange: true,
	});

	if (isSuccess) {
		projectsLists = data.data;
	}
	return (
		<>
			<div className='list-group list-group-flush'>
				{projectsLists &&
					projectsLists.map((projectsList, i) => {
						if (i < 5) {
							return (
								<ProjectItem
									key={i}
									title={projectsList.title}
									stage={projectsList.stage}
									dueDate={projectsList.end_date}
								/>
							);
						} else return null;
					})}
			</div>
		</>
	);
}

export default ProjectsList;

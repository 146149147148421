import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { store } from "app/store";
import {
  useGetImpactDataPointsByImpactIdQuery
} from "features/impacts/impactsSlice";
// import { getMonth } from "date-fns";
// import { getMonthName } from "common/utils";

ChartJS.register(
  ArcElement,

  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
);
// CV /TV
// Example with a Target Value (TV) of 100
//	   Score of 0: Range of == 0%
//     Score of 1: Range of >0% and <= 20% of target value
//         Example: AV = 13  (13 / 100 = .13 or 13% of target value)
//     Score of 2: Range of >20% and <= 40% of target value
//         Example: AV = 37  (37 / 100 = .37 or 37% of target value)
//     Score of 3: Range of >40% and <=60% of target value
//         Example: AV = 52  (52 / 100 = .52 or 52% of target value)
//     Score of 4: Range of >60% and <= 80% of target value
//         Example: AV = 65  (65 / 100 = .65 or 65% of target value)
//     Score of 5: Range of >80% and <= 100% of target value
//         Example: AV = 92  (92 / 100 = .92 or 92% of target value)

const FocusScore = ({
  userid,
  impactid,
  overallTarget,
  formType,
  targetDirection,
  trackingFrequency,
  fiscalYearStart,
}) => {
  let userId = userid;
  if (!userId) {
    userId = store.getState().auth.userData.id;
  }


  // Chart Model
  let scoreData = {
    labels: [], // will be populated later
    datasets: [
      {
        data: [], // will be populated later
        borderColor: "#1f2b5c",
        backgroundColor: "#9ac8ea",
        fill: true,
      },
    ],
    borderWidth: 1,
  };

  const {
    data: impactHistoryData,
    isError: isImpactHistoryError,
    isSuccess: isImpactHistorySuccess,
  } = useGetImpactDataPointsByImpactIdQuery(impactid, {
    refetchOnMountOrArgChange: true,
  });

  const getScore = (difference) => {
    let score = 0;
    if (difference > 0 && difference <= 0.1) {
      score = 1;
    } else if (difference > 0.1 && difference <= 0.2) {
      score = 2;
    } else if (difference > 0.2 && difference <= 0.3) {
      score = 3;
    } else if (difference > 0.3 && difference <= 0.4) {
      score = 4;
    } else if (difference > 0.4 && difference <= 0.5) {
      score = 5;
    } else if (difference > 0.5 && difference <= 0.6) {
      score = 6;
    } else if (difference > 0.6 && difference <= 0.7) {
      score = 7;
    } else if (difference > 0.7 && difference <= 0.8) {
      score = 8;
    } else if (difference > 0.8 && difference <= 0.9) {
      score = 9;
    } else if (difference > 0.9) {
      score = 10;
    }

    return score;
  };

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const calculateScoreDataPointsForImpact = (data) => {
    if (trackingFrequency === "Monthly") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const getLastThreeMonth = (month, year) => {
        const months = [];
        for (let i = 0; i < 3; i++) {
          const prevMonth = (month - i + 12) % 12;
          const prevYear = prevMonth > month ? year - 1 : year;
          months.push(`${monthNames[prevMonth]} ${prevYear}`);
        }
        return months.reverse();
      };
      const lastThreeMonth = getLastThreeMonth(currentMonth, currentYear);
      const filteredData = lastThreeMonth
        .map((month) => {
          const [monthName, year] = month.split(" ");
          const item = data.find((item) => {
            return item.title === monthName && item.year === parseInt(year);
          });
          return item
            ? { month: monthName, cumulative_value: item.cumulative_value }
            : null;
        })
        .filter((item) => item !== null);
      filteredData.forEach(function (point) {
        let currentAverage;
        let targetAverage;

        currentAverage = point.cumulative_value;
        targetAverage = overallTarget;
        scoreData.labels.push(point.month);
        if (targetDirection === "Increase") {
          scoreData.datasets[0].data.push(
            getScore(currentAverage / targetAverage),
          );
        }
      });
    }
    //tracking frequncy as quarterly
    if (trackingFrequency === "Quarterly") {
      const getCurrentQuarter = (currentMonth, fiscalYearStart) => {
        fiscalYearStart = 1;
        const fiscalMonth = (currentMonth - fiscalYearStart + 12) % 12;
        return Math.floor(fiscalMonth / 3) + 1;
      };
      const getLastThreeQuarters = (quarter, year) => {
        const quarters = [];
        for (let i = 0; i < 3; i++) {
          quarters.push({ quarter: `Q${quarter}`, year: year });
          quarter--;
          if (quarter < 1) {
            quarter = 4;
            year--;
          }
        }
        return quarters.reverse();
      };
      const currentQuarter = getCurrentQuarter(currentMonth, fiscalYearStart);
      const lastThreeQuarters = getLastThreeQuarters(
        currentQuarter,
        currentYear,
      );
      const filteredData = lastThreeQuarters.map((point) => {
        let quarter = point.quarter;
        let year = point.year;
        const item = data.find((item) => {
          return item.title === quarter && item.year === year;
        });
        return item
          ? { quarter, year, cumulative_value: item.cumulative_value }
          : { quarter, year, cumulative_value: 0 };
      });
      filteredData.forEach(function (point) {
        let currentAverage;
        let targetAverage;
        if (point != null) {
          currentAverage = point.cumulative_value;
          scoreData.labels.push(`${point.quarter} ${point.year}`);
        } else {
          currentAverage = 0;
          scoreData.labels.push(`${point.quarter} ${point.year}`);
        }
        targetAverage = overallTarget;

        scoreData.datasets[0].data.push(
          getScore(currentAverage / targetAverage),
        );
      });
    }
    //tracking frequnecy as yearly
    if (trackingFrequency === "Yearly") {
      const getLastThreeYears = (year) => {
        const years = [];
        for (let i = 0; i < 3; i++) {
          years.push({ year: year });
          year--;
        }
        return years.reverse();
      };

      const lastThreeYears = getLastThreeYears(currentYear);
      const filteredData = lastThreeYears.map((point) => {
        let year = point.year;
        const item = data.find((item) => {
          return Number(item.title) === year;
        });
        return item
          ? { year, cumulative_value: item.cumulative_value }
          : { year, cumulative_value: -1 };
      });
      filteredData.forEach(function (point) {
        let currentAverage;
        let targetAverage;
        if (point != null) {
          currentAverage = point.cumulative_value;
          scoreData.labels.push(`${point.year}`);
        } else {
          currentAverage = 0;
          scoreData.labels.push(`${point.year}`);
        }
        targetAverage = overallTarget;

        scoreData.datasets[0].data.push(
          getScore(currentAverage / targetAverage),
        );
      });
    }
  };
 

  if (formType === "Impact") {
    if (isImpactHistorySuccess) {
      calculateScoreDataPointsForImpact(impactHistoryData.data);
    }

    if (isImpactHistoryError) {
      calculateScoreDataPointsForImpact([]);
    }
  }

  return (
    <>
      <div className='card equal-height'>
        <div className='card-body'>
          <span className='type-title'>Focus Score</span>

          <div className='row'>
            <Line
              options={{
                scales: {
                  y: {
                    suggestedMin: 1,
                    suggestedMax: 10,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={scoreData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusScore;

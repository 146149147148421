import { useGetConsultantClientsQuery } from "features/clients/clientSlice";
import { ClientInventory } from "common";
import { store } from "app/store";

function ClientList() {
  let clientLists = [];
  let consultantId;
  if (store.getState().auth.userData)
    consultantId = store.getState().auth.userData.id;

  const { data, isSuccess } = useGetConsultantClientsQuery(consultantId, {
    refetchOnMountOrArgChange: true,
  });

  if (isSuccess) {
    clientLists = data.data;
  }
  return (
    <>
      <div className='row'>
        {clientLists.map((client, i) => {
          if (i < 4 && client) {
            return <ClientInventory key={i} client={client} />;
          } else return null;
        })}
      </div>
    </>
  );
}

export default ClientList;

import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetClientDetailsQuery } from "features/clients/clientSlice";
import logo from "../default-thumbnail.png";

import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
} from "features/user/userSlice";
import { formatPhoneNumber } from "common/helpers/formatPhoneNumber";
import Avatar from "react-avatar";
import { toast } from "react-toastify";

function UserProfile() {
  const userId = useSelector((state) => state.auth?.userData?.id) || null;

  const { data: userData } = useGetUserDetailsQuery(userId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const organizationId = useMemo(
    () => userData?.data?.organization,
    [userData],
  );

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phoneNumber: "",
  });

  const { data: companyData, isSuccess: isCompanySuccess } =
    useGetClientDetailsQuery(organizationId ?? skipToken, {
      refetchOnMountOrArgChange: true,
    });

  const [updateUserProfile, { isLoading: isUpdating }] =
    useUpdateUserMutation();

  // Handle Input Change
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "phoneNumber") {
      const formatted = formatPhoneNumber(value);
      setFormData({ ...formData, [name]: formatted });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phoneNumber, ...rest } = formData;
    const sanitizedPhone = phoneNumber?.replace(/\D/g, "");

    try {
      await updateUserProfile({
        id: userId,
        phoneNumber: sanitizedPhone,
        ...rest,
      }).unwrap();
      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error("Error updating profile");
    }
  };

  useEffect(() => {
    if (userData?.data) {
      setFormData({
        first_name: userData.data.first_name || "",
        last_name: userData.data.last_name || "",
        email: userData.data.email || "",
        phoneNumber: userData.data.phoneNumber || "",
      });
    }
  }, [userData]);

  return (
    <>
      <div className='container g-4'>
        <div className='row'>
          <div className='col-4'>
            <div className='card p-4' id='user-profile'>
              <div className='card-body d-flex align-items-center flex-column'>
                <Avatar
                  name={`${formData?.first_name}  ${formData?.last_name}`}
                  size='110'
                  color='#5784bb'
                  textSizeRatio={1.75}
                  textMarginRatio={0.2}
                  round={true}
                />
                <span className='user-display-name'>
                  {`${formData?.first_name}  ${formData?.last_name}`}
                </span>

                <span className='user-phone'>
                  Cell : {formatPhoneNumber(formData?.phoneNumber)}
                </span>
                <span className='user-phone'>Email: {formData?.email}</span>

                {isCompanySuccess ? (
                  <>
                    <span className='profile-section-title'>Company Info:</span>
                    <div className='d-flex align-items-center w-100 mb-3'>
                      <img
                        src={companyData?.data?.image ?? logo}
                        alt='company logo'
                        className='company-profile'
                        onError={(e) => (e.target.src = logo)}
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <div>
                        <p className='fw-bold mb-0 text-primary'>
                          {companyData?.data?.name}
                        </p>
                        <p className='text-muted small mb-0'>
                          {companyData?.data?.account_type}
                        </p>
                      </div>
                    </div>
                    <div className='company-phone mb-3'>
                      <p className='title'>Primary Contact Number:</p>
                      {companyData?.data?.primary_contact_phoneNumber ? (
                        <p>{companyData.data.primary_contact_phoneNumber}</p>
                      ) : null}
                    </div>

                    <div className='company-phone mb-3'>
                      <p className='title'>Secondary Contact Number:</p>
                      {companyData?.data?.secondary_contact_phoneNumber ? (
                        <p>{companyData.data.secondary_contact_phoneNumber}</p>
                      ) : null}
                    </div>

                    <div className='address mb-3'>
                      <p className='title'>Address:</p>
                      {companyData?.data?.address ? (
                        <p>{companyData.data.address}</p>
                      ) : null}
                      {companyData?.data?.city ||
                      companyData?.data?.country ||
                      companyData?.data?.zipcode ? (
                        <p>
                          {[
                            companyData?.data?.city,
                            companyData?.data?.country,
                            companyData?.data?.zipcode,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                      ) : null}
                    </div>

                    <div className='address mb-3'>
                      <p className='title'>Website:</p>
                      {companyData?.data?.website ? (
                        <p>{companyData.data.website}</p>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='card'>
              <div className='card-body'>
                <div id='personal-info' className='mb-5'>
                  <section className='mb-2'>
                    <span className='profile-section-title'>
                      Personal Info:
                    </span>
                  </section>
                  <form action=''>
                    <div className='row mb-3'>
                      <div className='col'>
                        <label
                          htmlFor='first_name'
                          className='form-label small mb-0'
                        >
                          First name
                        </label>
                        <input
                          id='first_name'
                          type='text'
                          className='form-control form-control-sm'
                          placeholder='Thomas'
                          aria-label='First name'
                          value={formData?.first_name}
                          onChange={handleChange}
                          name='first_name'
                        />
                      </div>
                      <div className='col'>
                        <label
                          htmlFor='last_name'
                          className='form-label small mb-0'
                        >
                          Last name
                        </label>
                        <input
                          id='last_name'
                          type='text'
                          className='form-control form-control-sm'
                          placeholder='Morrow'
                          aria-label='Last name'
                          value={formData?.last_name}
                          name='last_name'
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <label
                          htmlFor='e-mail'
                          className='form-label small mb-0'
                        >
                          Email
                        </label>
                        <input
                          id='e-mail'
                          type='text'
                          className='form-control form-control-sm'
                          placeholder='tom.morrow@clever.io'
                          aria-label='e-mail'
                          value={formData?.email}
                          onChange={handleChange}
                          name='email'
                        />
                      </div>
                      <div className='col'>
                        <label
                          htmlFor='mobile_phone'
                          className='form-label small mb-0'
                        >
                          Mobile phone
                        </label>
                        <input
                          id='mobile_phone'
                          type='text'
                          className='form-control form-control-sm'
                          placeholder='(XXX)XXX-XXXX'
                          aria-label='Mobile phone'
                          maxLength={14} // Enforce format length
                          value={formData?.phoneNumber}
                          name='phoneNumber'
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div id='company-info' className='mb-5'>
                  <section className='mb-2'>
                    <span className='profile-section-title'>Company Info:</span>
                  </section>
                  <form action=''>
                    <div className='row mb-4'>
                      <div className='col'>
                        <label
                          htmlFor='company-name'
                          className='form-label small mb-0'
                        >
                          Name:
                        </label>
                        <div>{companyData?.data?.name}</div>
                      </div>
                      {/* <div className='col'>
                        <label htmlFor='role' className='form-label small mb-0'>
                          Title
                        </label>
                        <input
                          id='title'
                          type='text'
                          className='form-control form-control-sm'
                          placeholder='title'
                          aria-label='title'
                          name='title'
                          value={companyFormData?.title}
                          onChange={handleCompanyFormChange}
                        />
                      </div>*/}
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm float-end text-light'
                      onClick={handleSubmit}
                      disabled={isUpdating}
                    >
                      {isUpdating ? "Saving..." : "Save All"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;

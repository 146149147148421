import { useGetAccountImpactsDpQuery } from "features/impacts/impactsSlice";

function FocusScoreClient({ clientId, formType }) {
  let clientid = clientId;
  let focusScore;

  const {
    data: impactDpByAccountData,
    isError: isImpactDpByAccountError,
    isSuccess: isImpactDpByAccountSuccess,
  } = useGetAccountImpactsDpQuery(clientid, {
    refetchOnMountOrArgChange: true,
  });

  const getScore = (difference) => {
    let score = 0;
    if (difference > 0 && difference <= 0.1) {
      score = 1;
    } else if (difference > 0.1 && difference <= 0.2) {
      score = 2;
    } else if (difference > 0.2 && difference <= 0.3) {
      score = 3;
    } else if (difference > 0.3 && difference <= 0.4) {
      score = 4;
    } else if (difference > 0.4 && difference <= 0.5) {
      score = 5;
    } else if (difference > 0.5 && difference <= 0.6) {
      score = 6;
    } else if (difference > 0.6 && difference <= 0.7) {
      score = 7;
    } else if (difference > 0.7 && difference <= 0.8) {
      score = 8;
    } else if (difference > 0.8 && difference <= 0.9) {
      score = 9;
    } else if (difference > 0.9) {
      score = 10;
    }

    return score;
  };
	const getScoreStatus = (score) => {
    const roundedScore = Math.round(score * 10) / 10;
    if (roundedScore >= 9 && roundedScore <= 10) {
      return "Great Progress";
    } else if (roundedScore >= 7 && roundedScore < 9) {
      return "Good";
    } else if (roundedScore >= 5 && roundedScore < 7) {
      return "Fair";
    } else if (roundedScore >= 1 && roundedScore < 5) {
      return "Problematic";
    } else {
      return "Invalid Score";
    }
  };
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  //New calculation for Focus score
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const findDataForCurrentorPreviousMonth = (month, year, dpData) => {
    let findData = null;
    let findMonth = month;
    let findYear = year;

    while (!findData && findYear >= 0 && dpData.length !== 0) {
      let monthName = monthNames[findMonth];
      let currentYear = findYear;
      findData = dpData.find(
        (item) =>
          item.title === monthName && item.year === parseInt(currentYear),
      );
      if (!findData) {
        findMonth--;
        if (findMonth < 0) {
          findMonth = 11;
          findYear--;
        }
      }
    }
    return findData ? findData : null;
  };

  const findDataForCurrentorPreviousYear = (year, dpData) => {
    let findData = null;
    let findYear = year;

    while (!findData && findYear >= 0 && dpData.length !== 0) {
      let currentYear = findYear;

      findData = dpData.find((item) => item.title === currentYear.toString());
      if (!findData) {
        findYear--;
      }
    }
    return findData ? findData : null;
  };
  const findDataForCurrentorPreviousQuarter = (quarter, year, dpData) => {
    let findData = null;
    let findQuarter = quarter;
    let findYear = year;

    while (!findData && findYear >= 0 && dpData.length !== 0) {
      let findQ = findQuarter;
      let currentYear = findYear;
      findData = dpData.find(
        (item) =>
          item.title === `Q${findQ}` && item.year === parseInt(currentYear),
      );
      if (!findData) {
        findQuarter--;
        if (findQuarter < 1) {
          findQuarter = 4;
          findYear--;
        }
      }
    }
    return findData ? findData : null;
  };
  const calculateScoreDataPoints = (data) => {
    let currentAverage = 0;
    let targetAverage = 0;
    data.forEach((i) => {
      let data_points = [];
      data_points = i.data_points;
      if (i.tracking_frequency === "Monthly") {
        let lastDp;
        if (data_points !== null) {
          lastDp = findDataForCurrentorPreviousMonth(
            currentMonth,
            currentYear,
            data_points,
          );
        }
        if (lastDp) {
          currentAverage =
            Number(lastDp.cumulative_value) + Number(currentAverage);
          targetAverage =
            Number(i.overall_target_value) + Number(targetAverage);
        }
      }
      //tracking freq is quarterly
      if (i.tracking_frequency === "Quarterly") {
        let lastDp;
        const convertMonthNameToNumber = (monthName) => {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          return months.indexOf(monthName) + 1;
        };
        let fiscalYearStart = convertMonthNameToNumber(i.fiscal_year_start);
        const getCurrentQuarter = (currentMonth, fiscalYearStart) => {
          const fiscalMonth = (currentMonth - fiscalYearStart + 12) % 12;
          return Math.floor(fiscalMonth / 3) + 1;
        };
        if (data_points !== null) {
          const currentQuarter = getCurrentQuarter(
            currentMonth,
            fiscalYearStart,
          );
          lastDp = findDataForCurrentorPreviousQuarter(
            currentQuarter,
            currentYear,
            data_points,
          );
        }
        if (lastDp) {
          currentAverage =
            Number(lastDp.cumulative_value) + Number(currentAverage);
          targetAverage =
            Number(i.overall_target_value) + Number(targetAverage);
        }
      }

      if (i.tracking_frequency === "Yearly") {
        let lastDp;
        if (data_points !== null) {
          lastDp = findDataForCurrentorPreviousYear(currentYear, data_points);
        }
        if (lastDp) {
          currentAverage =
            Number(lastDp.cumulative_value) + Number(currentAverage);
          targetAverage =
            Number(i.overall_target_value) + Number(targetAverage);
        }
      }
    });
    focusScore = getScore(currentAverage / targetAverage);

    return focusScore;
  };
  if (formType === "Client-Brief") {
    if (isImpactDpByAccountSuccess) {
      focusScore = calculateScoreDataPoints(impactDpByAccountData.data);
    }
    if (isImpactDpByAccountError) {
      focusScore = 0;
      calculateScoreDataPoints([]);
    }
  }

  return (
    <div>
      <div className='focus-score-circle d-flex align-items-center justify-content-center'>
        <span className='focus-score-value'>{focusScore}</span>
      </div>
      <span className='focus-score-status'>
        <strong>{getScoreStatus(focusScore)}</strong>
      </span>
    </div>
  );
}

export default FocusScoreClient;

import { useState, useEffect, useCallback } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useUpdateImpactDataPointMutation } from "features/impacts/impactsSlice";
import { yearOptions, monthOptions, convertDate } from "common/utils";
import { toast } from "react-toastify";


function EditTable({
  defaultData,
  columns,
  trackingFrequency,
  onSave,
  setOnSave,
  editingRowIndex,
  setEditingRowIndex,
  fiscalYearStart,
  setFeedback,
  targetDirection,
  newDataPoints,
  overallTargetValue,
}) {
  const [data, setData] = useState(() => [...defaultData]);
  const [localEdits, setLocalEdits] = useState();

  useEffect(() => {
    setData([...defaultData].sort((a, b) => a.id - b.id));
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      onCellEdit: (rowIndex, columnId, value) => {
        setData((oldData) =>
          oldData.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                [columnId]: value,
              };
            }
            return row;
          }),
        );

        setLocalEdits((prev) => ({
          ...prev,
          id: data[rowIndex]?.id,
          [columnId]: value,
        }));
      },
    },
  });

  const calculateTotalTargetSum = useCallback(() => {
    const TargetSum = data.reduce(
      (acc, point) => acc + parseFloat(point.target_value || 0),
      0,
    );

    return TargetSum;
  }, [data]);

  const calculateRemainingValue = () => {
    const existingTargetSum = data.reduce(
      (acc, point) => acc + parseFloat(point.target_value || 0),
      0,
    );
    return overallTargetValue - existingTargetSum;
  };

  const renderProgressCheckIcon = (progress_check) => {
    let symbol = null;
    let tooltip = "";

    if (progress_check) {
      if (targetDirection === "Increase") {
        if (progress_check === "above_target") {
          symbol = "checkmark";
          tooltip =
            "Congratulations! Actual Value exceeded Target Value. Excellent progress towards increase target.";
        } else if (progress_check === "on_target") {
          symbol = "checkmark";
          tooltip = "You are on target. Keep up the great work.";
        } else if (progress_check === "below_target") {
          symbol = "exclamation";
          tooltip =
            "Attention: Actual Value below Target Value. Please reassess to stay on track towards increase target.";
        }
      } else if (targetDirection === "Decrease") {
        if (progress_check === "below_target") {
          symbol = "exclamation";
          tooltip =
          "Attention: Actual Value below Target Value. Please reassess to stay on track towards target.";
        } else if (progress_check === "on_target") {
          symbol = "checkmark";
          tooltip = "You are on target. Keep up the great work.";
        } else if (progress_check === "above_target") {
          symbol = "checkmark";
          tooltip = "Congratulations! Actual Value exceeded Target Value. Excellent progress towards target.";
        }
      }
    }

    return (
      <span title={tooltip}>
        {symbol === "checkmark" ? (
          <i className='bi bi-check-circle-fill' style={{ color: "green" }}></i>
        ) : symbol === "exclamation" ? (
          <i
            className='bi bi-exclamation-circle-fill'
            style={{ color: "red" }}
          ></i>
        ) : null}
      </span>
    );
  };

  const [updateDataPoint] = useUpdateImpactDataPointMutation({
    refetchOnMountOrArgChange: true,
  });

  const saveChanges = useCallback(async () => {
    const totalTargetSum = calculateTotalTargetSum();

    try {
      if (localEdits?.actual_value === "" || localEdits?.target_value === "") {
        setFeedback({
          error: "Target and Actual values are required.",
        });

        setTimeout(() => {
          setFeedback({
            error: "",
            successMessage: "",
          });
        }, 3000);
        return;
      }
      if (localEdits?.actual_value < 0 || localEdits?.target_value < 0) {
        setFeedback({
          error: "The 'Target/Actual Value' cannot be negative.",
        });

        setTimeout(() => {
          setFeedback({
            error: "",
            successMessage: "",
          });
        }, 3000);
        return;
      }
      if (localEdits && localEdits.id) {
          const response =   await updateDataPoint({
            dataPointId: localEdits.id,
            body: localEdits,
          });
          if (response?.data?.status === "success") {
          if (totalTargetSum > overallTargetValue) {
            setFeedback({
              error: "",
              successMessage: "The sum of target values for the data points exceeds the overall target value for Impact.",
            });
            toast.success("The data point has been updated successfully.");
            setTimeout(() => {
              setFeedback({
                error: "",
                successMessage: "",
              });
            }, 3000);
          }     
        }
      
      setTimeout(() => {
        setFeedback({
          error: "",
          successMessage: "",
        });
      }, 3000);
    }} catch (error) {
      console.error("Error updating data point:", error);
      setFeedback({
        error: "Failed to update the data point.",
      });
    } finally {
      setOnSave(false);
      setLocalEdits(null);
    }
  }, [
    localEdits,
    updateDataPoint,
    setOnSave,
    setFeedback,
    overallTargetValue,
    calculateTotalTargetSum,
  ]);

  useEffect(() => {
    if (onSave) {
      saveChanges();
    }
  }, [onSave, saveChanges]);

  const handleCellChange = (rowIndex, columnId, value) => {
    table.options.meta?.onCellEdit(rowIndex, columnId, value);
  };

  const handleRowClick = (rowIndex) => {
    if (editingRowIndex === null && newDataPoints?.length === 0) {
      setEditingRowIndex(rowIndex);
    }
  };

  const getTooltipForQuarter = (quarter) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const startMonthIndex = months.indexOf(fiscalYearStart);
    if (startMonthIndex === -1) return "";

    const shiftedMonths = [
      ...months.slice(startMonthIndex),
      ...months.slice(0, startMonthIndex),
    ];

    switch (quarter) {
      case "Q1":
        return `${shiftedMonths[0]}, ${shiftedMonths[1]}, ${shiftedMonths[2]}`;
      case "Q2":
        return `${shiftedMonths[3]}, ${shiftedMonths[4]}, ${shiftedMonths[5]}`;
      case "Q3":
        return `${shiftedMonths[6]}, ${shiftedMonths[7]}, ${shiftedMonths[8]}`;
      case "Q4":
        return `${shiftedMonths[9]}, ${shiftedMonths[10]}, ${shiftedMonths[11]}`;
      default:
        return "";
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <table className='custom-impact-table'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={
                      header.column.id === "progress_check"
                        ? { width: "50px" }
                        : {}
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleRowClick(row.index)}
                className={
                  (targetDirection === "Increase" ||
                    targetDirection === "Decrease") &&
                  row.original.progress_check === "below_target"
                    ? "red-highlight"
                    : ""
                }
                style={{
                  cursor: editingRowIndex === null ? "pointer" : "default",
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.column.id === "progress_check" ? (
                      <div
                        className='non-editable-cell mr-2'
                        style={{ textAlign: "center" }}
                      >
                        {renderProgressCheckIcon(cell.getValue())}
                      </div>
                    ) : cell.column.id === "title" &&
                      trackingFrequency === "Yearly" ? (
                      editingRowIndex === row.index ? (
                        <select
                          value={cell.getValue()}
                          onChange={(e) =>
                            handleCellChange(
                              row.index,
                              cell.column.id,
                              e.target.value,
                            )
                          }
                          className='editable-cell'
                          style={{
                            width: "100%",
                            border: "none",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span>{cell.getValue()}</span>
                      )
                    ) : cell.column.id === "title" &&
                      trackingFrequency === "Monthly" ? (
                      editingRowIndex === row.index ? (
                        <select
                          value={cell.getValue()}
                          onChange={(e) =>
                            handleCellChange(
                              row.index,
                              cell.column.id,
                              e.target.value,
                            )
                          }
                          className='editable-cell'
                          style={{
                            width: "100%",
                            border: "none",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        >
                          {monthOptions.map((month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span>{cell.getValue()}</span>
                      )
                    ) : cell.column.id === "title" &&
                      trackingFrequency === "Quarterly" ? (
                      <span
                        title={getTooltipForQuarter(cell.getValue())}
                        style={{ cursor: "pointer" }}
                      >
                        {editingRowIndex === row.index ? (
                          <input
                            value={cell.getValue()}
                            onChange={(e) =>
                              handleCellChange(
                                row.index,
                                cell.column.id,
                                e.target.value,
                              )
                            }
                            className='editable-cell'
                            style={{
                              width: "100%",
                              border: "none",
                              padding: "5px",
                              boxSizing: "border-box",
                            }}
                          />
                        ) : (
                          cell.getValue()
                        )}
                      </span>
                    ) : cell.column.id === "year" ? (
                      editingRowIndex === row.index ? (
                        <select
                          value={cell.getValue()}
                          onChange={(e) =>
                            handleCellChange(
                              row.index,
                              cell.column.id,
                              e.target.value,
                            )
                          }
                          className='editable-cell'
                          style={{
                            width: "100%",
                            border: "none",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span>{cell.getValue()}</span>
                      )
                    ) : cell.column.id === "target_value" ? (
                      editingRowIndex === row.index ? (
                        <input
                          type='number'
                          value={cell.getValue()}
                          title={
                            calculateRemainingValue() <= 0
                              ? "Overall target met, no remaining value required."
                              : `${calculateRemainingValue()} to reach overall target.`
                          }
                          onChange={(e) =>
                            handleCellChange(
                              row.index,
                              cell.column.id,
                              e.target.value,
                            )
                          }
                          className='editable-cell'
                          style={{
                            width: "100%",
                            border: "none",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        />
                      ) : (
                        <span>{cell.getValue()}</span>
                      )
                    ) : cell.column.id === "actual_value" ? (
                      editingRowIndex === row.index ? (
                        <input
                          type='number'
                          value={cell.getValue()}
                          onChange={(e) =>
                            handleCellChange(
                              row.index,
                              cell.column.id,
                              e.target.value,
                            )
                          }
                          className='editable-cell'
                          style={{
                            width: "100%",
                            border: "none",
                            padding: "5px",
                            boxSizing: "border-box",
                          }}
                        />
                      ) : (
                        <span>{cell.getValue()}</span>
                      )
                    ) : cell.column.id === "cumulative_value" ? (
                      <div
                        className='non-editable-cell'
                        style={{ padding: "5px" }}
                      >
                        {cell.getValue()}
                      </div>
                    ) : cell.column.id === "created_on_date" ? (
                      <div
                        className='non-editable-cell'
                        style={{ padding: "5px" }}
                      >
                        {convertDate(cell.getValue())}
                      </div>
                    ) : editingRowIndex === row.index ? (
                      <input
                        value={cell.getValue()}
                        onChange={(e) =>
                          handleCellChange(
                            row.index,
                            cell.column.id,
                            e.target.value,
                          )
                        }
                        className='editable-cell'
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      />
                    ) : (
                      <span>{cell.getValue()}</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EditTable;
